import { createMuiTheme } from '@material-ui/core'

import overrides from './overrides'
import colors from '../values/colors'

const theme = createMuiTheme({
    overrides,
    palette: {
        primary: { main: colors.blues.one },
        secondary: { main: colors.purple },
    },
    typography: {
        fontFamily: 'Poppins',
    },
    props: {
        MuiSelect: {
            disableUnderline: true,
        },
    },
})

export default theme
