import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { GreenCheckbox } from '../../../components'
import useStyles from '../material.styles'
import { Tag } from '../../../types/Tags'
import styles from './styles.module.css'

type Props = {
    row: Tag
    index: number
    handleEdit: (tag: Tag) => void
    handleActiveOrDisable: (checked: boolean, docId: string) => void
}

const TagItem: React.FC<Props> = ({
    row,
    index,
    handleEdit,
    handleActiveOrDisable,
}) => {
    const classes = useStyles()

    return (
        <TableRow key={`${index}-${row.tag}`} className={classes.tableRow}>
            <TableCell className={classes.tableText}>
                {!!row.image && (
                    <img
                        src={row.image}
                        alt={row.title}
                        className={styles.tableImg}
                    />
                )}
            </TableCell>
            <TableCell className={classes.tableText}>{row.tag}</TableCell>
            <TableCell className={classes.tableText}>
                {!!row.title && row.title}
            </TableCell>
            <TableCell className={classes.tableText}>
                {!!row.subtitle && row.subtitle}
            </TableCell>
            <TableCell className={classes.tableText}>
                {!!row.major ? 'Sim' : 'Não'}
            </TableCell>
            <TableCell></TableCell>
            <TableCell className={classes.tableText}>
                <GreenCheckbox
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => handleActiveOrDisable(checked, row.docId!)}
                    checked={!!row.active}
                />
            </TableCell>
            <TableCell>
                <EditIcon
                    onClick={() => handleEdit(row)}
                    className={classes.editBtn}
                />
            </TableCell>
        </TableRow>
    )
}

export default TagItem
