import React from 'react'
import { TableRow, TableCell, withStyles, Checkbox } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import EditIcon from '@material-ui/icons/Edit'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled'
import styles from '../styles.module.css'
import { Meditation } from '../../../types/Meditations'
import colors from '../../../values/colors'
import useStyles from '../material.styles'
import DateUtils from '../../../utils/DateUtils'

const GreenCheckbox = withStyles({
    root: {
        color: colors.blacks.one,
        '&$checked': {
            color: colors.greens.one,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

type Props = {
    row: Meditation
    index: number
    handleEdit: (meditation: Meditation) => void
    handleActiveOrDisable: (checked: boolean, docId: string) => void
}
const MeditationItem: React.FC<Props> = ({
    row,
    index,
    handleEdit,
    handleActiveOrDisable,
}) => {
    const classes = useStyles()
    const [play, setPlay] = React.useState<boolean>(false)

    const playOrPause = () => {
        setPlay(!play)
    }

    return (
        <TableRow key={`${index}-${row.title}`}>
            <TableCell component="th" scope="row">
                <img
                    src={row.imageUrl}
                    alt={row.title}
                    className={styles.tableImg}
                />
            </TableCell>
            <TableCell className={classes.tableText}>{row.title}</TableCell>
            <TableCell className={classes.tableText}>
                {row.description}
            </TableCell>
            <TableCell className={classes.tableText}>
                {DateUtils.timeConvert(row.time)}
            </TableCell>
            <TableCell className={classes.tableText}>
                {row.tags && row.tags.join(', ')}
            </TableCell>
            <TableCell className={classes.tableText}>
                {!!row.canChooseBackground ? 'Sim' : 'Não'}
            </TableCell>
            <TableCell className={classes.tableText}>
                {!!row.premium ? 'Não' : 'Sim'}
            </TableCell>
            <TableCell className={classes.tableText}>
                <GreenCheckbox
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => handleActiveOrDisable(checked, row.docId!)}
                    checked={!!row.active}
                />
            </TableCell>
            <TableCell>
                <EditIcon
                    onClick={() => handleEdit(row)}
                    className={classes.editBtn}
                />
            </TableCell>
            <TableCell>
                {play ? (
                    <PauseCircleFilled
                        onClick={() => playOrPause()}
                        className={classes.playBtn}
                    />
                ) : (
                    <PlayCircleFilled
                        onClick={() => playOrPause()}
                        className={classes.playBtn}
                    />
                )}
                {play && (
                    <audio className={styles.player} autoPlay controls>
                        <source src={row.audioVoice} type="audio/mpeg" />
                    </audio>
                )}
            </TableCell>
        </TableRow>
    )
}

export default MeditationItem
