import React, { useState, useContext, useEffect } from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { CircularProgress } from '@material-ui/core'
import firebase from 'firebase'
import moment from 'moment'

import { ImageUploader } from '../../../components'
import { TextField } from '@material-ui/core'
import { FileType } from '../../../types/Files'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../../context'
import globalStyles from '../../../values/globalStyles'
import { Phrase, CreatePhrasePack } from '../../../types/Phrases'
import DateUtils from '../../../utils/DateUtils'
import MaskUtils from '../../../utils/MaskUtils'

type Props = {
    phrase?: Phrase
    onCancel: () => void
    showList: () => void
}

const COLLECTION_NAME = 'frontage'

const PhraseForm: React.FC<Props> = ({ phrase, onCancel, showList }) => {
    const classes = useStyles()
    const [imageSrc, setImageSrc] = useState<FileType>()
    const [newPhraseImageUrl, setPhraseImageUrl] = useState<string>('')
    const [docId] = useState<string>(phrase?.docId || `${new Date().getTime()}`)
    const [phraseText, setPhraseText] = useState<string>(phrase?.phrase || '')
    const [imageUrl] = useState<string>(phrase?.imageUrl || '')
    const [startDate, setStartDate] = useState<string>(
        phrase?.startDate
            ? moment(phrase?.startDate.seconds * 1000).format('DD/MM/YYYY')
            : ''
    )
    const [startHour, setStartHour] = useState<string>(
        phrase?.startDate
            ? moment(phrase?.startDate.seconds * 1000).format('HH:mm')
            : ''
    )
    const [finalDate, setFinalDate] = useState<string>(
        phrase?.finalDate
            ? moment(phrase?.finalDate.seconds * 1000).format('DD/MM/YYYY')
            : ''
    )
    const [finalHour, setFinalHour] = useState<string>(
        phrase?.finalDate
            ? moment(phrase?.finalDate.seconds * 1000).format('HH:mm')
            : ''
    )

    const [imgInputRef] = React.useState<React.RefObject<HTMLInputElement>>(
        React.createRef()
    )

    const { showMessage, setGlobalLoading, globalLoading } = useContext(
        ApplicationContext
    )

    const triggerImgInput = () => {
        if (imgInputRef.current && !globalLoading) {
            imgInputRef.current.click()
        }
    }

    const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!globalLoading) {
            if (e.target.files && e.target.files.length) {
                const arrFiles = Array.from(e.target.files)
                const files = arrFiles.map((file, index) => {
                    const src = window.URL.createObjectURL(file)
                    return { file, src }
                })
                setImageSrc(files[files.length - 1])
            }
        }
    }

    const clearFields = () => {
        setImageSrc(undefined)
        setPhraseImageUrl('')
        setPhraseText('')
        setStartDate('')
        setFinalDate('')
    }

    const onCancelLocal = () => {
        clearFields()
        onCancel()
    }

    const uploadFile = (file: FileType) => {
        const storageRef = firebase.storage().ref()
        const fileName = phrase ? phrase.docId : docId // if have meditation, the user is editing
        const uploadTask = storageRef
            .child(`frontage/${fileName}`) //padronize name to aways override file
            .put(file.file)

        uploadTask.on(
            'state_changed',
            function (snapshot) {},
            function (error) {
                showMessage(error.message)
            },
            function () {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        setPhraseImageUrl(downloadURL)
                    })
            }
        )
    }

    const onSaveMeditation = (phrasePack: CreatePhrasePack) => {
        setGlobalLoading(true)
        uploadFile(phrasePack.imageSrc!)
    }

    const [needToUploadInEdit, setNeedToUploadInEdit] = useState<boolean>(false)

    const onEditMeditation = (phrasePack: CreatePhrasePack) => {
        setGlobalLoading(true)
        if (phrasePack.imageSrc) {
            uploadFile(phrasePack.imageSrc)
            setNeedToUploadInEdit(true)
        } else {
            delete phrasePack['docId']
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .doc(docId)
                .set(phrasePack, { merge: true })
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A frase foi alterada')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }
    }

    useEffect(() => {
        if (
            !!newPhraseImageUrl &&
            newPhraseImageUrl !== '' &&
            !!phraseText &&
            phraseText !== '' &&
            DateUtils.isValidDateString(startDate) &&
            DateUtils.isValidDateString(finalDate) &&
            DateUtils.isValidHourString(startHour) &&
            DateUtils.isValidHourString(finalHour) &&
            !phrase
        ) {
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .doc(docId)
                .set({
                    createdAt: firebase.firestore.Timestamp.now(),
                    imageUrl: newPhraseImageUrl,
                    active: true,
                    phrase: phraseText,
                    finalDate: DateUtils.stringToTimestamp(
                        `${finalDate} ${finalHour}`,
                        'DD/MM/YYYY HH:mm'
                    ),
                    startDate: DateUtils.stringToTimestamp(
                        `${startDate} ${startHour}`,
                        'DD/MM/YYYY HH:mm'
                    ),
                })
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A nova frase foi adicionada')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }

        if (needToUploadInEdit) {
            if (!!newPhraseImageUrl && newPhraseImageUrl !== '') {
                firebase
                    .firestore()
                    .collection(COLLECTION_NAME)
                    .doc(docId)
                    .set(
                        {
                            updatedAt: firebase.firestore.Timestamp.now(),
                            imageUrl: newPhraseImageUrl,
                            active: true,
                            phrase: phraseText,
                            finalDate: DateUtils.stringToTimestamp(
                                `${finalDate} ${finalHour}`,
                                'DD/MM/YYYY HH:mm'
                            ),
                            startDate: DateUtils.stringToTimestamp(
                                `${startDate} ${startHour}`,
                                'DD/MM/YYYY HH:mm'
                            ),
                        },
                        { merge: true }
                    )
                    .then(() => {
                        clearFields()
                        showList()
                        showMessage('A frase foi alterada')
                    })
                    .catch((error) => showMessage(error.message))
                    .finally(() => setGlobalLoading(false))
            }
        }
    }, [
        docId,
        finalDate,
        finalHour,
        needToUploadInEdit,
        newPhraseImageUrl,
        phrase,
        phraseText,
        setGlobalLoading,
        showList,
        showMessage,
        startDate,
        startHour,
    ])

    const onSaveLocal = () => {
        if (globalLoading) return

        if (!phraseText || phraseText.trim() === '') {
            showMessage('Digite o texto da frase')
            return
        }

        if (!DateUtils.isValidDateString(startDate)) {
            showMessage('Digite uma data válida de início')
            return
        }

        if (!DateUtils.isValidDateString(finalDate)) {
            showMessage('Digite uma data final válida')
            return
        }

        if (!DateUtils.isValidHourString(startHour)) {
            showMessage('Digite a hora da data inicial')
            return
        }

        if (!DateUtils.isValidHourString(finalHour)) {
            showMessage('Digite a hora da data final')
            return
        }

        const datesDiff = moment(finalDate).diff(moment(startDate), 'd')

        if (datesDiff < 0) {
            showMessage('A data final não pode ser anterior a data de início')
            return
        }

        if (!phrase) {
            if (!imageSrc) {
                showMessage('Escolha uma imagem de fundo para a frase')
                return
            }

            onSaveMeditation({
                createdAt: firebase.firestore.Timestamp.now().toDate(),
                active: true,
                phrase: phraseText,
                imageUrl,
                imageSrc,
                finalDate: DateUtils.stringToTimestamp(
                    `${finalDate} ${finalHour}`,
                    'DD/MM/YYYY HH:mm'
                ),
                startDate: DateUtils.stringToTimestamp(
                    `${startDate} ${startHour}`,
                    'DD/MM/YYYY HH:mm'
                ),
            })
        } else {
            const imageObj = imageSrc ? { imageSrc } : {}
            onEditMeditation({
                ...phrase,
                updatedAt: firebase.firestore.Timestamp.now().toDate(),
                active: true,
                phrase: phraseText,
                imageUrl,
                finalDate: DateUtils.stringToTimestamp(
                    `${finalDate} ${finalHour}`,
                    'DD/MM/YYYY HH:mm'
                ),
                startDate: DateUtils.stringToTimestamp(
                    `${startDate} ${startHour}`,
                    'DD/MM/YYYY HH:mm'
                ),
                ...imageObj,
            })
        }
    }

    const getImgSource = () => {
        if (imageSrc) {
            return imageSrc?.src
        }

        if (imageUrl) {
            return imageUrl
        }

        return ''
    }

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <ImageUploader
                    children={() => (
                        <div className={styles.imageUploadBtn}>
                            {!!imageSrc || imageUrl ? (
                                <img
                                    src={getImgSource()}
                                    alt="imagem"
                                    className={styles.imgPreview}
                                />
                            ) : (
                                <>
                                    <CloudUploadIcon
                                        className={classes.cloudUploadIcon}
                                    />
                                    <label
                                        className={styles.imageUploadBtnLabel}
                                    >
                                        imagem
                                    </label>
                                </>
                            )}
                        </div>
                    )}
                    onChange={onChangeAvatar}
                    inputRef={imgInputRef}
                    triggerInput={triggerImgInput}
                />
                {(!!imageSrc || imageUrl) && (
                    <label className={styles.imageRemoveBtnLabel}>
                        remover imagem
                    </label>
                )}
            </div>
            <div
                className={`${styles.column} ${styles.marginBottom8} ${styles.paddingSecondColumn}`}
            >
                <div className={`${styles.column} margin-bottom-24`}>
                    <label className={styles.titleField}>Frase</label>
                    <TextField
                        value={phraseText}
                        onChange={(event) => setPhraseText(event.target.value)}
                        classes={{ root: classes.textField }}
                        size="small"
                        variant="outlined"
                        disabled={globalLoading}
                    />
                </div>
                <div className={styles.row}>
                    <div className={`${styles.column} margin-bottom-24`}>
                        <label className={styles.titleField}>
                            Data de início
                        </label>
                        <TextField
                            value={startDate}
                            onChange={(event) =>
                                setStartDate(
                                    MaskUtils.toDate(
                                        MaskUtils.toOnlyNumbers(
                                            event.target.value
                                        )
                                    )
                                )
                            }
                            inputProps={{ maxLength: 10 }}
                            classes={{ root: classes.textDateHourField }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={`${styles.column} margin-bottom-24`}>
                        <label className={styles.titleField}>Hora</label>
                        <TextField
                            value={startHour}
                            onChange={(event) =>
                                setStartHour(
                                    MaskUtils.toHour(
                                        MaskUtils.toOnlyNumbers(
                                            event.target.value
                                        )
                                    )
                                )
                            }
                            classes={{ root: classes.textDateHourField }}
                            inputProps={{ maxLength: 10 }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.column} margin-bottom-24`}>
                        <label className={styles.titleField}>Data final</label>
                        <TextField
                            value={finalDate}
                            onChange={(event) =>
                                setFinalDate(
                                    MaskUtils.toDate(
                                        MaskUtils.toOnlyNumbers(
                                            event.target.value
                                        )
                                    )
                                )
                            }
                            inputProps={{ maxLength: 10 }}
                            classes={{ root: classes.textDateHourField }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={`${styles.column} margin-bottom-24`}>
                        <label className={styles.titleField}>Hora</label>
                        <TextField
                            value={finalHour}
                            onChange={(event) =>
                                setFinalHour(
                                    MaskUtils.toHour(
                                        MaskUtils.toOnlyNumbers(
                                            event.target.value
                                        )
                                    )
                                )
                            }
                            classes={{ root: classes.textDateHourField }}
                            inputProps={{ maxLength: 10 }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                </div>
                <div className={styles.alignEnd}>
                    {globalLoading ? (
                        <CircularProgress
                            size={20}
                            classes={{ root: classes.circularProgress }}
                        />
                    ) : (
                        <>
                            <label
                                onClick={onCancelLocal}
                                className={
                                    globalStyles
                                        ? styles.cancelBtn
                                        : styles.disabledCancelBtn
                                }
                            >
                                Cancelar
                            </label>
                            <label
                                onClick={onSaveLocal}
                                className={
                                    globalStyles
                                        ? styles.saveBtn
                                        : styles.disabledSaveBtn
                                }
                            >
                                Salvar
                            </label>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PhraseForm
