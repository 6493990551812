import { makeStyles } from '@material-ui/core/'
import colors from '../../../values/colors'
const baseTextField: React.CSSProperties = {
    background: colors.whites.two,
    boxSizing: 'border-box',
    borderRadius: 2,
    outline: 'none',
    fontFamily: 'Poppins',
    fontSize: 12,
    letterSpacing: 0.15,
    color: colors.blacks.two,
}

const useStyles = makeStyles({
    cloudUploadIcon: {
        color: colors.blacks.three,
        cursor: 'pointer',
    },
    closeIcon: {
        color: colors.blacks.three,
        cursor: 'pointer',
    },
    textField: {
        ...baseTextField,
        width: 331,
        marginRight: 24,
        '& > *': {
            borderRadius: 2,
        },
    },
    textTimeField: {
        ...baseTextField,
        width: 150,
        '& > *': {
            borderRadius: 2,
        },
    },
    textTagField: {
        ...baseTextField,
        width: 200,
        '& > *': {
            borderRadius: 2,
        },
    },
    textDescriptionField: {
        ...baseTextField,
        fontSize: 14,
        width: '100%',
        marginBottom: 16,
        borderColor: colors.greys.four,
    },
    autoCompleteTags: {
        ...baseTextField,
        fontSize: 14,
        width: '100%',
    },
    circularProgress: {
        color: colors.blacks.one,
    },
    selectField: {
        width: 233,
        background: colors.whites.one,
        borderRadius: 5,
        marginRight: 24,
        '& > *': {
            fontFamily: 'Poppins',
            fontSize: 14,
            letterSpacing: -0.24,
            color: colors.blacks.three,
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px !important',
        },
    },
})

export default useStyles
