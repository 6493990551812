import React, { useState } from 'react'
import {
    TableRow,
    TableCell,
    withStyles,
    Checkbox,
    Modal,
    Paper,
} from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'

import styles from '../styles.module.css'
import colors from '../../../values/colors'
import useStyles from '../material.styles'
import { Phrase } from '../../../types/Phrases'

const GreenCheckbox = withStyles({
    root: {
        color: colors.blacks.one,
        '&$checked': {
            color: colors.greens.one,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

type Props = {
    row: Phrase
    index: number
    handleEdit: (row: Phrase) => void
    handleDelete: (row: Phrase) => void
    handleActiveOrDisable: (checked: boolean, docId: string) => void
}
const BreathItem: React.FC<Props> = ({
    row,
    index,
    handleEdit,
    handleDelete,
    handleActiveOrDisable,
}) => {
    const classes = useStyles()
    const [openDelete, setOpenDelete] = useState<boolean>(false)

    const openDeleteConfirmation = (phrase: Phrase) => {
        setOpenDelete(true)
    }
    return (
        <TableRow key={`${index}-${row.phrase}`}>
            <TableCell component="th" scope="row">
                <img
                    src={row.imageUrl}
                    alt={row.phrase}
                    className={styles.tableImg}
                />
            </TableCell>
            <TableCell className={classes.tableText}>{row.phrase}</TableCell>
            <TableCell className={classes.tableText}>
                {moment(row.startDate.seconds * 1000).format(
                    'DD/MM/YYYY HH:mm'
                )}
            </TableCell>
            <TableCell className={classes.tableText}>
                {moment(row.finalDate.seconds * 1000).format(
                    'DD/MM/YYYY HH:mm'
                )}
            </TableCell>
            <TableCell className={classes.tableText}>
                {moment(row.createdAt).format('DD/MM/YYYY')}
            </TableCell>
            <TableCell className={classes.tableText}>
                {row.updatedAt && moment(row.updatedAt).format('DD/MM/YYYY')}
            </TableCell>
            <TableCell className={classes.tableText}>
                <GreenCheckbox
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => handleActiveOrDisable(checked, row.docId!)}
                    checked={!!row.active}
                />
            </TableCell>
            <TableCell>
                <EditIcon
                    onClick={() => handleEdit(row)}
                    className={classes.editBtn}
                />
                <DeleteIcon
                    onClick={() => openDeleteConfirmation(row)}
                    className={classes.editBtn}
                />
            </TableCell>
            <Modal
                open={openDelete}
                onClose={() => setOpenDelete(true)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={'centering'}
            >
                <Paper className={styles.paper}>
                    <label>Deseja realmente excluir esta frase?</label>
                    <div className={styles.alignEnd}>
                        <>
                            <label
                                onClick={() => setOpenDelete(false)}
                                className={styles.saveBtn}
                            >
                                Cancelar
                            </label>
                            <label
                                onClick={() => {
                                    handleDelete(row)
                                    setOpenDelete(false)
                                }}
                                className={styles.cancelBtn}
                            >
                                Confirmar
                            </label>
                        </>
                    </div>
                </Paper>
            </Modal>
        </TableRow>
    )
}

export default BreathItem
