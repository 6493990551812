import Validator from 'validatorjs'
// import en from 'validatorjs/src/lang/en'

// // TODO: Fix this? -> https://github.com/skaterdav85/validatorjs/issues/229
// Validator.setMessages('en', en)

class ValidatorUtils {
    static isValidEmail = (email: string): boolean | void => {
        const data = { email }
        const rules = { email: 'required|email' }
        return new Validator(data, rules).passes()
    }

    static isValidPhone = (phone: string): boolean | void => {
        const data = { phone }
        const rules = { phone: 'required|between:10,11' }
        return new Validator(data, rules).passes()
    }

    static isValidName = (name: string): boolean | void => {
        const data = { name }
        const rules = { name: 'required|min:3' }
        return new Validator(data, rules).passes()
    }
}

export default ValidatorUtils
