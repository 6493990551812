import React, { useState, useContext, useEffect } from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { CircularProgress, Select } from '@material-ui/core'
import firebase from 'firebase'
import { Breath, CreateBreathPack } from '../../../types/Breaths'
import { ImageUploader } from '../../../components'
import { TextField, TextareaAutosize } from '@material-ui/core'
import { FileType } from '../../../types/Files'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../../context'
import globalStyles from '../../../values/globalStyles'

type Props = {
    breath?: Breath
    onCancel: () => void
    showList: () => void
}

const BreathForm: React.FC<Props> = ({ breath, onCancel, showList }) => {
    const classes = useStyles()
    const [imageSrc, setImageSrc] = useState<FileType>()
    const [newBreathImageUrl, setBreathImageUrl] = useState<string>('')
    const [docId] = useState<string>(breath?.docId || `${new Date().getTime()}`)
    const [title, setTitle] = useState<string>(breath?.title || '')
    const [imageUrl] = useState<string>(breath?.imageUrl || '')
    const [inhale, setInHale] = useState<number>(breath?.inhale || 0)
    const [exhale, setExHale] = useState<number>(breath?.exhale || 0)
    const [holdInhale, setHoldInhale] = useState<number>(
        breath?.holdInhale || 0
    )
    const [holdExhale, setHoldExhale] = useState<number>(
        breath?.holdExhale || 0
    )
    const [totalTimes, setTotalTimes] = useState<number>(
        breath?.totalTimes || 0
    )
    const [description, setDescription] = useState<string>(
        breath?.description || ''
    )
    const [imgInputRef] = React.useState<React.RefObject<HTMLInputElement>>(
        React.createRef()
    )

    const { showMessage, setGlobalLoading, globalLoading } = useContext(
        ApplicationContext
    )

    const [premium, setPremium] = useState<boolean>(!!breath?.premium)

    const handleSelectPremium = (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode
    ) => {
        const value = event.target.value as string
        setPremium(value !== 'Sim')
    }

    const triggerImgInput = () => {
        if (imgInputRef.current && !globalLoading) {
            imgInputRef.current.click()
        }
    }

    const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!globalLoading) {
            if (e.target.files && e.target.files.length) {
                const arrFiles = Array.from(e.target.files)
                const files = arrFiles.map((file, index) => {
                    const src = window.URL.createObjectURL(file)
                    return { file, src }
                })
                setImageSrc(files[files.length - 1])
            }
        }
    }

    const clearFields = () => {
        setImageSrc(undefined)
        setBreathImageUrl('')
        setTitle('')
        setInHale(0)
        setExHale(0)
        setHoldInhale(0)
        setHoldExhale(0)
        setTotalTimes(0)
        setDescription('')
        setPremium(false)
    }

    const onCancelLocal = () => {
        clearFields()
        onCancel()
    }

    const uploadFile = (file: FileType) => {
        const storageRef = firebase.storage().ref()
        const fileName = breath ? breath.docId : docId // if have meditation, the user is editing
        const uploadTask = storageRef
            .child(`breath/${fileName}`) //padronize name to aways override file
            .put(file.file)

        uploadTask.on(
            'state_changed',
            function (snapshot) {},
            function (error) {
                showMessage(error.message)
            },
            function () {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        setBreathImageUrl(downloadURL)
                    })
            }
        )
    }

    const onSaveMeditation = (breathPack: CreateBreathPack) => {
        setGlobalLoading(true)
        uploadFile(breathPack.imageSrc!)
    }

    const [needToUploadInEdit, setNeedToUploadInEdit] = useState<boolean>(false)

    const onEditMeditation = (breathPack: CreateBreathPack) => {
        setGlobalLoading(true)
        if (breathPack.imageSrc) {
            uploadFile(breathPack.imageSrc)
            setNeedToUploadInEdit(true)
        } else {
            firebase
                .firestore()
                .collection('breaths')
                .doc(breathPack?.docId)
                .set(breathPack, { merge: true })
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A respiração foi alterada')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }
    }

    useEffect(() => {
        const checkTimes = () =>
            inhale !== undefined &&
            holdInhale !== undefined &&
            exhale !== undefined &&
            holdExhale !== undefined
        if (
            !!newBreathImageUrl &&
            newBreathImageUrl !== '' &&
            !!title &&
            title !== '' &&
            !!description &&
            description !== '' &&
            checkTimes() &&
            !!totalTimes &&
            totalTimes !== 0 &&
            !breath
        ) {
            firebase
                .firestore()
                .collection('breaths')
                .doc(docId)
                .set({
                    createdAt: firebase.firestore.Timestamp.now(),
                    imageUrl: newBreathImageUrl,
                    active: true,
                    title,
                    description,
                    inhale,
                    exhale,
                    holdInhale,
                    holdExhale,
                    totalTimes,
                    premium,
                })
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A nova meditação foi adicionada')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }

        console.log('NEED EDIT UPLOAD?', needToUploadInEdit)
        if (needToUploadInEdit) {
            console.log('EnteringHere')
            if (!!newBreathImageUrl && newBreathImageUrl !== '') {
                firebase
                    .firestore()
                    .collection('breaths')
                    .doc(docId)
                    .set(
                        {
                            updatedAt: firebase.firestore.Timestamp.now().toDate(),
                            imageUrl: newBreathImageUrl,
                            active: true,
                            title,
                            description,
                            inhale,
                            exhale,
                            holdInhale,
                            holdExhale,
                            totalTimes,
                            premium,
                        },
                        { merge: true }
                    )
                    .then(() => {
                        clearFields()
                        showList()
                        showMessage('A respiração foi alterada')
                    })
                    .catch((error) => showMessage(error.message))
                    .finally(() => setGlobalLoading(false))
            }
        }
    }, [
        description,
        needToUploadInEdit,
        docId,
        exhale,
        holdExhale,
        holdInhale,
        imageUrl,
        inhale,
        setGlobalLoading,
        showList,
        showMessage,
        title,
        totalTimes,
        newBreathImageUrl,
        breath,
        premium,
    ])

    const onSaveLocal = () => {
        if (globalLoading) return

        if (!title || title.trim() === '') {
            showMessage('Digite o título da respiração')
            return
        }

        if (!description || description.trim() === '') {
            showMessage('Digite uma descriação para a respiração')
            return
        }

        if (inhale === undefined) {
            showMessage('Digite o tempo de inalação')
            return
        }

        if (holdInhale === undefined) {
            showMessage('Digite o tempo sustentado ao inalar')
            return
        }

        if (exhale === undefined) {
            showMessage('Digite o tempo para exalar')
            return
        }

        if (holdExhale === undefined) {
            showMessage('Digite o tempo sustentado ao exalar')
            return
        }

        if (
            inhale === 0 &&
            holdInhale === 0 &&
            exhale === 0 &&
            holdExhale === 0
        ) {
            showMessage(
                'Pelo menos uma dos tempos de respiração deve ser maior que 0'
            )
            return
        }

        if (!totalTimes || totalTimes === 0) {
            showMessage('Digite o número de repetições')
            return
        }

        if (!breath) {
            if (!imageSrc) {
                showMessage('Escolha uma imagem para a respiração')
                return
            }

            onSaveMeditation({
                createdAt: firebase.firestore.Timestamp.now().toDate(),
                active: true,
                description,
                exhale,
                holdExhale,
                holdInhale,
                imageUrl,
                inhale,
                title,
                totalTimes,
                premium,
                imageSrc,
            })
        } else {
            const imageObj = imageSrc ? { imageSrc } : {}
            onEditMeditation({
                ...breath,
                createdAt: firebase.firestore.Timestamp.now().toDate(),
                active: true,
                description,
                exhale,
                holdExhale,
                holdInhale,
                inhale,
                title,
                totalTimes,
                premium,
                ...imageObj,
            })
        }
    }

    const getImgSource = () => {
        if (imageSrc) {
            return imageSrc?.src
        }

        if (imageUrl) {
            return imageUrl
        }

        return ''
    }

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <ImageUploader
                    children={() => (
                        <div className={styles.imageUploadBtn}>
                            {!!imageSrc || imageUrl ? (
                                <img
                                    src={getImgSource()}
                                    alt="imagem"
                                    className={styles.imgPreview}
                                />
                            ) : (
                                <>
                                    <CloudUploadIcon
                                        className={classes.cloudUploadIcon}
                                    />
                                    <label
                                        className={styles.imageUploadBtnLabel}
                                    >
                                        imagem
                                    </label>
                                </>
                            )}
                        </div>
                    )}
                    onChange={onChangeAvatar}
                    inputRef={imgInputRef}
                    triggerInput={triggerImgInput}
                />
                {(!!imageSrc || imageUrl) && (
                    <label className={styles.imageRemoveBtnLabel}>
                        remover imagem
                    </label>
                )}
            </div>
            <div className={`${styles.column} ${styles.paddingSecondColumn}`}>
                <div className={`${styles.row} ${styles.marginBottom8}`}>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Título</label>
                        <TextField
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            classes={{ root: classes.textField }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Inalar</label>
                        <TextField
                            value={inhale}
                            onChange={(event) =>
                                setInHale(parseInt(event.target.value))
                            }
                            classes={{ root: classes.textTimeBreathField }}
                            size="small"
                            variant="outlined"
                            type={'number'}
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Seg. inalar</label>
                        <TextField
                            value={holdInhale}
                            onChange={(event) =>
                                setHoldInhale(parseInt(event.target.value))
                            }
                            classes={{ root: classes.textTimeBreathField }}
                            size="small"
                            variant="outlined"
                            type={'number'}
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Exalar</label>
                        <TextField
                            value={exhale}
                            onChange={(event) =>
                                setExHale(parseInt(event.target.value))
                            }
                            classes={{ root: classes.textTimeBreathField }}
                            size="small"
                            variant="outlined"
                            type={'number'}
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Seg. exalar</label>
                        <TextField
                            value={holdExhale}
                            onChange={(event) =>
                                setHoldExhale(parseInt(event.target.value))
                            }
                            classes={{ root: classes.textTimeBreathField }}
                            size="small"
                            variant="outlined"
                            type={'number'}
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={`column margin-right-16`}>
                        <label className={styles.titleField}>Repetir</label>
                        <TextField
                            value={totalTimes}
                            onChange={(event) =>
                                setTotalTimes(parseInt(event.target.value))
                            }
                            classes={{
                                root: classes.textTimeBreathNoMarginField,
                            }}
                            size="small"
                            variant="outlined"
                            type={'number'}
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={`column`}>
                        <label className={styles.titleField}>
                            Meditação gratuita
                        </label>
                        <Select
                            value={premium ? 'Não' : 'Sim'}
                            onChange={handleSelectPremium}
                            className={classes.selectField}
                            variant={'outlined'}
                        >
                            <option
                                className={styles.selectOptions}
                                value={'Não'}
                            >
                                Não
                            </option>
                            <option
                                className={styles.selectOptions}
                                value={'Sim'}
                            >
                                Sim
                            </option>
                        </Select>
                    </div>
                </div>

                <div className={styles.column}>
                    <label className={styles.titleField}>Descrição</label>
                    <TextareaAutosize
                        value={description}
                        onChange={(
                            event: React.ChangeEvent<HTMLTextAreaElement>
                        ) => setDescription(event.target.value)}
                        className={classes.textDescriptionField}
                        rowsMin={7}
                        disabled={globalLoading}
                    />
                </div>
                <div className={styles.alignEnd}>
                    {globalLoading ? (
                        <CircularProgress
                            size={20}
                            classes={{ root: classes.circularProgress }}
                        />
                    ) : (
                        <>
                            <label
                                onClick={onCancelLocal}
                                className={
                                    globalStyles
                                        ? styles.cancelBtn
                                        : styles.disabledCancelBtn
                                }
                            >
                                Cancelar
                            </label>
                            <label
                                onClick={onSaveLocal}
                                className={
                                    globalStyles
                                        ? styles.saveBtn
                                        : styles.disabledSaveBtn
                                }
                            >
                                Salvar
                            </label>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BreathForm
