import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import isLoggedIn from '../core/isLoggedIn'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const renderPage = props => {
        if (isLoggedIn()) return <Component {...props} />
        return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
    }

    return <Route {...rest} render={renderPage} />
}
export default PrivateRoute
