import React, { useState, useEffect, useContext } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import firebase from 'firebase'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Fab,
    TablePagination,
    LinearProgress,
    InputAdornment,
    TextField,
    Button,
} from '@material-ui/core/'

import { Tag } from '../../types/Tags'
import { BasePage } from '../../container'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../context'
import TagItem from './layouts/TagItem'
import TagForm from './layouts/Form'
import { routes } from '..'

const TagsPage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const [tags, setTags] = useState<Tag[]>([])
    const [searchText, setSearchText] = useState<string>('')
    const [rowsPerPage, setRowsPerPage] = useState<number>(25)
    const [page, setPage] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [isNew, setIsNew] = useState<boolean>(false)
    const [isInEdit, setIsInEdit] = useState<boolean>(false)
    const [tagToEdit, setTagToEdit] = useState<Tag>()

    const { showMessage, globalLoading } = useContext(ApplicationContext)

    useEffect(() => {
        firebase
            .firestore()
            .collection('tags')
            .get()
            .then((snapshot) => {
                setTotal(snapshot.size)
            })
            .catch(() => showMessage('Falha ao pegar o total de items'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew, isInEdit])

    const updateTags = (snapshot) => {
        const tagsBuilder: any[] = []
        snapshot.forEach((doc) => {
            tagsBuilder.push({
                docId: doc.id,
                ...doc.data(),
            })
        })
        setTags(tagsBuilder)
        setLoading(false)
    }

    useEffect(() => {
        const existTextToSearch = !!searchText && searchText !== ''

        if (existTextToSearch) {
            if (existTextToSearch) {
                setLoading(true)
                firebase
                    .firestore()
                    .collection('tags')
                    .get()
                    .then((snapshot) => {
                        const tagBuilder: any[] = []
                        snapshot.forEach((doc) => {
                            if (
                                Object.values(doc.data())
                                    .join(',')
                                    .toUpperCase()
                                    .match(searchText.toUpperCase())
                            )
                                tagBuilder.push({
                                    docId: doc.id,
                                    ...doc.data(),
                                })
                        })
                        setTags(tagBuilder)
                    })
                    .catch((error) => {
                        console.log(error.message)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        } else {
            if (tags.length === 0) {
                setLoading(true)
                firebase
                    .firestore()
                    .collection('tags')
                    .orderBy('createdAt', 'desc')
                    .limit(rowsPerPage)
                    .onSnapshot((snapshot) => {
                        if (snapshot.size === 0) {
                            setLoading(false)
                        } else {
                            updateTags(snapshot)
                        }
                    })
            }
        }
    }, [searchText, tags, rowsPerPage, isNew, isInEdit])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setPage(newPage)

        let creator = firebase
            .firestore()
            .collection('tags')
            .orderBy('createdAt', 'desc')

        if (page < newPage) {
            creator = creator
                .startAfter(tags[tags.length - 1].createdAt)
                .limit(rowsPerPage)
        } else {
            creator = creator
                .endBefore(tags[0].createdAt)
                .limitToLast(rowsPerPage)
        }

        creator

            .get()
            .then(updateTags)
            .finally(() => setLoading(false))
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value))
        setPage(0)
        setLoading(true)
        firebase
            .firestore()
            .collection('tags')
            .orderBy('createdAt', 'desc')
            .limit(parseInt(event.target.value))
            .get()
            .then((snapshot) => updateTags(snapshot))
            .catch((error) => {
                showMessage(error.message)
            })
            .finally(() => setLoading(false))
    }

    const handleActiveOrDisable = (checked: boolean, docId: string) => {
        setLoading(true)
        const backupTags = [...tags]
        const cloneTags = [...tags]
        const index = cloneTags.findIndex((med) => med.docId === docId)
        cloneTags.splice(index, 1, {
            ...cloneTags[index],
            active: checked,
        })
        setTags(cloneTags)

        firebase
            .firestore()
            .collection('tags')
            .doc(docId)
            .set({ active: checked }, { merge: true })
            .then(() => {
                showMessage(`A tag foi ${checked ? '' : 'in'}ativada`)
            })
            .catch((error) => {
                setTags(backupTags)
                showMessage(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleEdit = (tag: Tag) => {
        setTagToEdit(tag)
        setIsInEdit(true)
    }

    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    }

    const handleNewMeditation = () => {
        setIsNew(true)
    }

    const onCancelMeditation = () => {
        setIsNew(false)
        setIsInEdit(false)
        setTagToEdit(undefined)
    }

    const goToOrdenatePage = () => {
        history.push(routes.orderTagsPage.path)
    }

    return (
        <BasePage>
            {(loading || globalLoading) && <LinearProgress />}
            <div className={styles.container}>
                <Fab
                    onClick={() => handleNewMeditation()}
                    classes={{ root: classes.actionButton }}
                    aria-label="add"
                >
                    <AddIcon classes={{ root: classes.addIcon }} />
                </Fab>
                {(isNew || isInEdit) && (
                    <TagForm
                        tag={tagToEdit}
                        onCancel={onCancelMeditation}
                        showList={() => onCancelMeditation()}
                    />
                )}
                {!isInEdit && (
                    <div className={styles.tableWrapper}>
                        <div className={styles.searchWrapper}>
                            <Button
                                classes={{ root: classes.button }}
                                variant="contained"
                                size={'small'}
                                onClick={goToOrdenatePage}
                            >
                                ORDENAR
                            </Button>
                            <TextField
                                id="outlined-start-adornment"
                                value={searchText}
                                onChange={handleSearchText}
                                className={classes.searchField}
                                placeholder={'Localizar'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant={'outlined'}
                            />
                        </div>
                        <TableContainer component={Paper}>
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Imagem
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Nome da tag
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Título
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Subtítulo
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Tag Principal
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell
                                            className={classes.tableActive}
                                        >
                                            Ativado
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {tags.map((tag: Tag, index: number) => (
                                        <TagItem
                                            row={tag}
                                            index={index}
                                            handleEdit={() => handleEdit(tag)}
                                            handleActiveOrDisable={
                                                handleActiveOrDisable
                                            }
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={total}
                                className={classes.pagination}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage={'Itens por página:'}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </div>
                )}
            </div>
        </BasePage>
    )
}

export default TagsPage
