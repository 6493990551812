import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import moment from 'moment'
import { DashboardCard } from '../../components'
import { BasePage } from '../../container'
import styles from './styles.module.css'

const Dashboard: React.FC = () => {
    const [meditations, setMeditations] = useState<number>(0)
    const [usersOnline, setUsersOnline] = useState<number>(0)
    const [usersAll, setUsersAll] = useState<number>(0)
    const [premiumUsers, setPremiumUsers] = useState<number>(0)

    useEffect(() => {
        firebase
            .firestore()
            .collection('counter_meditations')
            .doc(moment().format('YYYY-MM-DD'))
            .get()
            .then((doc) => {
                if (doc.exists && !!doc.data()) {
                    setMeditations(doc.data()!.done)
                }
            })
            .catch((error) => console.log(error.message))

        firebase
            .firestore()
            .collection('user_profile')
            .get()
            .then((snap) => {
                setUsersAll(snap.size)
            })
            .catch((error) => console.log(error.message))

        firebase
            .firestore()
            .collection('user_profile')
            .get()
            .then((snap) => {
                const docBuilder: any[] = []
                snap.forEach((doc) => {
                    const user = doc.data()
                    if (
                        user.premiumAppStore ||
                        user.premiumHotmart ||
                        user.premiumPlayStore
                    ) {
                        docBuilder.push(user)
                    }
                })
                setPremiumUsers(docBuilder.length)
            })
            .catch((error) => console.log(error.message))

        firebase
            .firestore()
            .collection('user_profile')
            .where('status', '==', 'online')
            .get()
            .then((snap) => {
                const docBuilder: any[] = []
                snap.forEach((doc) => {
                    const user = doc.data()
                    docBuilder.push(user)
                })
                setUsersOnline(docBuilder.length)
            })
            .catch((error) => console.log(error.message))
    }, [])

    return (
        <BasePage>
            <div className={styles.container}>
                <DashboardCard
                    title={'meditações hoje'}
                    count={meditations}
                    icon={'person'}
                />
                <DashboardCard
                    title={'usuários Online atualmente'}
                    count={usersOnline}
                    icon={'circle'}
                />
                <DashboardCard
                    title={'Usuários totais'}
                    count={usersAll}
                    icon={'group'}
                />
                <DashboardCard
                    title={'Usuários Premiums ativos'}
                    count={premiumUsers}
                    icon={'crown'}
                />
            </div>
        </BasePage>
    )
}

export default Dashboard
