import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import {
    Paper,
    Button,
    createMuiTheme,
    MuiThemeProvider,
    InputAdornment,
    OutlinedInput,
    IconButton,
    CircularProgress,
} from '@material-ui/core'
import firebase from 'firebase'
import colors from '../../values/colors'
import { images } from '../../assets'
import styles from './styles.module.css'
import ValidatorUtils from '../../utils/ValidatorUtils'
import firebaseErrors from '../../values/firebaseErrors'
import { routes } from '../index'
import ApplicationContext from '../../context'
import { BasePage } from '../../container'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        PaperRoot: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(4),
            width: theme.spacing(40),
            height: theme.spacing(41),
        },
        textInputRoot: {
            '& > *': {
                fontFamily: 'Poppins',
                fontSize: 14,
            },
            marginBottom: theme.spacing(3),
        },
        button: {
            backgroundColor: colors.backgroundBlue,
            marginBottom: theme.spacing(3),
            '& > *': {
                fontFamily: 'Poppins',
                fontSize: 14,
                fontWeight: 600,
                letterSpacing: 1.25,
                textTransform: 'uppercase',
                color: colors.white,
                height: 36,
            },
            '&:hover': {
                backgroundColor: colors.backgroundBlue,
                opacity: 0.8,
            },
        },
        circularProgress: {
            color: colors.white,
        },
    })
)

const ButtonTheme = createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
})

const LoginPage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const { showMessage } = useContext(ApplicationContext)

    const onChangEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const onChangPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handlePasswordVisible = () => setShowPassword(!showPassword)

    const submit = React.useCallback(() => {
        if (!email || email.length === 0) {
            showMessage('Digite seu e-mail')
            return
        }

        if (!ValidatorUtils.isValidEmail(email)) {
            showMessage('Digite seu e-mail')
            return
        }

        if (!password || password.length === 4) {
            showMessage('Digite sua senha')
            return
        }

        if (password.length < 4) {
            showMessage('Sua senha deve ter pelo menos 4 caracteres')
            return
        }

        setLoading(true)
        firebase
            .firestore()
            .collection('user_profile')
            .where('email', '==', email)
            .get()
            .then((snapshot) => {
                if (snapshot.size === 0) {
                    setLoading(false)
                    showMessage(
                        'Não encontramos uma conta com este endereço de e-mail'
                    )
                }

                snapshot.forEach((doc) => {
                    if (doc.data().isAdmin) {
                        //if user is admin, make auth of him
                        firebase
                            .auth()
                            .signInWithEmailAndPassword(email, password)
                            .then()
                            .catch((error) => {
                                setLoading(false)
                                console.log(error.message)
                                showMessage(firebaseErrors[error.code])
                            })
                    } else {
                        setLoading(false)
                        showMessage(
                            'Somente usuários administradores podem acessar o painel'
                        )
                    }
                })
            })
            .catch((error) => {
                setLoading(false)
                showMessage(error.message)
            })
    }, [email, password, showMessage])

    const openForgotPassword = () => {
        history.push(routes.recoveryPasswordPage.path)
    }

    const returnEventListener = React.useCallback(
        ({ key }) => {
            if (key === 'Enter') {
                submit()
            }
        },
        [submit]
    )

    useEffect(() => {
        document.addEventListener('keydown', returnEventListener)
        return () => {
            document.removeEventListener('keydown', returnEventListener)
        }
    }, [returnEventListener])

    return (
        <BasePage hideAuthContent>
            <div className={styles.container}>
                <img
                    src={images.logo}
                    alt={'logo do sistema'}
                    className={styles.logo}
                />
                <Paper classes={{ root: classes.PaperRoot }}>
                    <label className={styles.ctaLabel}>Faça seu login</label>
                    <OutlinedInput
                        id="email"
                        type="email"
                        placeholder={'E-mail'}
                        value={email}
                        classes={{ root: classes.textInputRoot }}
                        onChange={onChangEmail}
                    />
                    <OutlinedInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder={'Senha'}
                        value={password}
                        onChange={onChangPassword}
                        classes={{ root: classes.textInputRoot }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handlePasswordVisible}
                                    onMouseDown={handlePasswordVisible}
                                >
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <MuiThemeProvider theme={ButtonTheme}>
                        <Button
                            classes={{ root: classes.button }}
                            variant="contained"
                            onClick={submit}
                        >
                            {!loading ? (
                                'ENTRAR'
                            ) : (
                                <CircularProgress
                                    size={20}
                                    classes={{ root: classes.circularProgress }}
                                />
                            )}
                        </Button>
                    </MuiThemeProvider>
                    <label
                        onClick={openForgotPassword}
                        className={styles.forgetPasswordLabel}
                    >
                        Esqueceu sua{' '}
                        <span className={styles.blueWord}>senha?</span>
                    </label>
                </Paper>
            </div>
        </BasePage>
    )
}

export default LoginPage
