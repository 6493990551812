import React from 'react'
import { User } from '../types/User'

export type Context = {
    authUser?: User
    setAuthUser: (user: User) => void
    doLogout: () => void
    showMessage: (msg: string) => void
    closeMessage: () => void
    globalLoading: boolean
    setGlobalLoading: (state: boolean) => void
    snackVisible: boolean
    snackMessage: string
}

const ApplicationContext = React.createContext<Context>({
    authUser: undefined,
    setAuthUser: (user: User) => {},
    doLogout: () => {},
    showMessage: (msg: string) => {},
    snackVisible: false,
    snackMessage: '',
    globalLoading: false,
    closeMessage: () => {},
    setGlobalLoading: (state: boolean) => {},
})

export default ApplicationContext
