import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import useStyles from '../material.styles'
import { Tag } from '../../../types/Tags'
import styles from './styles.module.css'

interface DraggableWrapperProps {
    provided?: DraggableProvided
    snapshot?: DraggableStateSnapshot
}

const DraggableWrapper: React.FC<DraggableWrapperProps> = ({
    children,
    provided,
    snapshot,
}) => {
    if (!provided || !snapshot)
        return (
            <div
                className={styles.questionBox}
                style={{
                    boxSizing: 'border-box',
                    userSelect: 'none',
                    minHeight: '50px',
                    color: '#fff',
                }}
            >
                {children}
            </div>
        )

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={styles.questionBox}
            style={{
                boxSizing: 'border-box',
                width: '100%',
                userSelect: 'none',
                padding: '24px 45px',
                margin: '0 0 40px 0',
                minHeight: '50px',
                position: snapshot.isDragging ? 'absolute' : 'relative',
                color: '#fff',
                ...provided.draggableProps.style,
            }}
        >
            {children}
        </div>
    )
}

type Props = {
    row: Tag
    index: number
    ContainerComponent: string
    ContainerProps: any
    provided?: DraggableProvided
    snapshot?: DraggableStateSnapshot
    style: Object
}

const TagItem: React.FC<Props> = ({
    row,
    index,
    provided,
    style,
    ContainerProps,
    ContainerComponent,
    snapshot,
}) => {
    const classes = useStyles()

    return (
        <DraggableWrapper provided={provided} snapshot={snapshot}>
            <TableRow
                ref={provided.innerRef}
                style={style}
                ContainerComponent={ContainerComponent}
                ContainerProps={ContainerProps}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                key={row.docId}
                className={classes.tableRow}
            >
                <TableCell className={classes.tableText}>{row.index}</TableCell>
                <TableCell className={classes.tableText}>
                    {!!row.image && (
                        <img
                            src={row.image}
                            alt={row.title}
                            className={styles.tableImg}
                        />
                    )}
                </TableCell>
                <TableCell className={classes.tableText}>{row.tag}</TableCell>
                <TableCell className={classes.tableText}>
                    {!!row.title && row.title}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {!!row.subtitle && row.subtitle}
                </TableCell>
                <TableCell className={classes.tableText}>
                    {!!row.major ? 'Sim' : 'Não'}
                </TableCell>
                <TableCell className={classes.tableText}>
                    <DragIndicatorIcon className={classes.editBtn} />
                </TableCell>
            </TableRow>
        </DraggableWrapper>
    )
}

export default TagItem
