import React, { useState, useContext, useEffect } from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { TextField } from '@material-ui/core'
import firebase from 'firebase'
import globalStyles from '../../../values/globalStyles'
import { CreateTagsPack } from '../../../types/Tags'
import { ImageUploader, GreenCheckbox } from '../../../components'
import ApplicationContext from '../../../context'
import { Tag } from '../../../types/Tags'
import useStyles from './material.styles'
import styles from './styles.module.css'
import { FileType } from '../../../types/Files'

const COLLECTION_NAME = 'tags'

type Props = {
    tag?: Tag
    onCancel: () => void
    showList: () => void
}

const TagForm: React.FC<Props> = ({ tag, onCancel, showList }) => {
    const classes = useStyles()

    const { showMessage, setGlobalLoading, globalLoading } = useContext(
        ApplicationContext
    )

    const [docId] = useState<string>(tag?.docId || `${new Date().getTime()}`)
    const [tagImageUrl, setTagImageUrl] = useState<string>('')
    const [name, setName] = useState<string>(tag?.tag || '')
    const [title, setTitle] = useState<string>(tag?.title || '')
    const [lastIndex, setLastIndex] = useState<number>(tag?.index || 0)
    const [subTitle, setSubTitle] = useState<string>(tag?.subtitle || '')
    const [isMajor, setIsMajor] = useState<boolean>(tag?.major || false)
    const [imageUrl, setImageUrl] = useState<string>(tag?.image || '')
    const [willRemoveImg, setWillRemoveImg] = useState<boolean>(false)
    const [imageSrc, setImageSrc] = useState<FileType>()
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [imgInputRef] = React.useState<React.RefObject<HTMLInputElement>>(
        React.createRef()
    )

    useEffect(() => {
        //This code is required for save the index
        //The index prop is used to order tags in app
        //Exist one screen to order the indexes
        if (!tag) {
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .where('major', '==', isMajor)
                .get()
                .then((snapshot) => {
                    setLastIndex(snapshot.size + 1)
                })
                .catch((error) => {
                    showMessage(error.message)
                })
        }
    }, [showMessage, isMajor, tag])

    const triggerImgInput = () => {
        if (imgInputRef.current && !globalLoading) {
            imgInputRef.current.click()
        }
    }

    const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!globalLoading) {
            if (e.target.files && e.target.files.length) {
                const arrFiles = Array.from(e.target.files)
                const files = arrFiles.map((file, index) => {
                    const src = window.URL.createObjectURL(file)
                    return { file, src }
                })
                setImageSrc(files[files.length - 1])
            }
        }
    }

    const onChangeMajor = (checked: boolean) => {
        setIsMajor(checked)
    }

    const clearFields = () => {
        setTitle('')
        setName('')
        setSubTitle('')
        setIsMajor(false)
        setImageSrc(undefined)
        setTagImageUrl('')
    }

    const onCancelLocal = () => {
        clearFields()
        onCancel()
    }

    const uploadFile = (file: FileType) => {
        const storageRef = firebase.storage().ref()
        const fileName = tag ? tag.docId : docId // if have tag, the user is editing
        const uploadTask = storageRef
            .child(`tag/${fileName}`) //padronize name to aways override file
            .put(file.file)

        uploadTask.on(
            'state_changed',
            function (snapshot) {},
            function (error) {
                showMessage(error.message)
            },
            function () {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        setTagImageUrl(downloadURL)
                    })
            }
        )
    }

    const deleteFile = async () => {
        const storageRef = firebase.storage().ref()
        const fileName = tag ? tag.docId : docId // if have tag, the user is editing
        await storageRef.child(`tag/${fileName}`).delete()
    }

    const onRemoveImg = () => {
        setImageSrc(undefined)
        setTagImageUrl('')
        setWillRemoveImg(true)
        setImageUrl('')
    }

    const onSaveTag = (tagPack: CreateTagsPack) => {
        setGlobalLoading(true)
        if (tagPack.imageSrc) {
            uploadFile(tagPack.imageSrc)
        } else {
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .add(tagPack)
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A tag foi criada')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }
    }

    const onEditTag = async (tagPackToEdit: CreateTagsPack) => {
        setGlobalLoading(true)
        if (tagPackToEdit.imageSrc) {
            uploadFile(tagPackToEdit.imageSrc)
        } else {
            try {
                if (willRemoveImg) {
                    await deleteFile()
                    await firebase
                        .firestore()
                        .collection(COLLECTION_NAME)
                        .doc(docId)
                        .set(tagPackToEdit, { merge: true })

                    clearFields()
                    showList()
                    showMessage('A tag foi editada')
                } else {
                    await firebase
                        .firestore()
                        .collection(COLLECTION_NAME)
                        .doc(docId)
                        .set(tagPackToEdit, { merge: true })

                    clearFields()
                    showList()
                    showMessage('A tag foi editada')
                }
            } catch (error) {
                showMessage(error.message)
            } finally {
                setGlobalLoading(false)
            }
        }
    }

    const onSaveLocal = () => {
        if (globalLoading) return

        if (!name || name === '') {
            showMessage('Digite o título da tag')
            return
        }
        // eslint-disable-next-line no-useless-escape
        if (/[A-ZÀ-ú !@#$%^&*()\+-=\[\]{};':"\\|,.<>\/?]/.test(name)) {
            showMessage(
                'Utilize apenas letra minúsculas ou underline, sem acentuação e sem espaços'
            )
            return
        }

        let imgControlObj = {}

        if (imageSrc) imgControlObj = { imageSrc }

        if (willRemoveImg) imgControlObj = { image: '' } //to Remove img from db
        if (!tag) {
            onSaveTag({
                createdAt: firebase.firestore.Timestamp.now().toDate(),
                index: lastIndex,
                active: true,
                tag: name,
                major: !!isMajor,
                title: title || '',
                subtitle: subTitle || '',
                ...imgControlObj,
            })
        } else {
            onEditTag({
                ...tag,
                index: lastIndex,
                createdAt: firebase.firestore.Timestamp.now().toDate(),
                active: true,
                tag: name,
                major: !!isMajor,
                title: title || '',
                subtitle: subTitle || '',
                ...imgControlObj,
            })
        }
    }

    useEffect(() => {
        if (
            !!tagImageUrl &&
            tagImageUrl !== '' &&
            !!name &&
            name !== '' &&
            !isSaving
        ) {
            let tagObj: Tag = {
                index: lastIndex,
                active: true,
                tag: name,
                major: isMajor,
                title: title || '',
                subtitle: subTitle || '',
                image: tagImageUrl,
            }

            if (!tag) {
                tagObj = {
                    ...tagObj,
                    createdAt: firebase.firestore.Timestamp.now().toDate(),
                }
            } else {
                tagObj = {
                    ...tagObj,
                    updatedAt: firebase.firestore.Timestamp.now().toDate(),
                }
            }
            setIsSaving(true)
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .doc(docId)
                .set(tagObj, { merge: true })
                .then(() => {
                    clearFields()
                    showList()
                    showMessage(`A tag foi ${!!tag ? 'alterada' : 'criada'}`)
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }
    }, [
        docId,
        isMajor,
        isSaving,
        tagImageUrl,
        name,
        setGlobalLoading,
        showList,
        showMessage,
        subTitle,
        tag,
        title,
        lastIndex,
    ])

    const getImgSource = () => {
        if (imageSrc) {
            return imageSrc?.src
        }

        if (imageUrl) {
            return imageUrl
        }

        return ''
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <ImageUploader
                        children={() => (
                            <div className={styles.imageUploadBtn}>
                                {!!imageSrc || imageUrl ? (
                                    <img
                                        src={getImgSource()}
                                        alt="imagem"
                                        className={styles.imgPreview}
                                    />
                                ) : (
                                    <>
                                        <CloudUploadIcon
                                            className={classes.cloudUploadIcon}
                                        />
                                        <label
                                            className={
                                                styles.imageUploadBtnLabel
                                            }
                                        >
                                            imagem
                                        </label>
                                    </>
                                )}
                            </div>
                        )}
                        onChange={onChangeAvatar}
                        inputRef={imgInputRef}
                        triggerInput={triggerImgInput}
                    />
                    {(!!imageSrc || imageUrl) && (
                        <label
                            onClick={onRemoveImg}
                            className={styles.imageRemoveBtnLabel}
                        >
                            remover imagem
                        </label>
                    )}
                </div>
                <div className={styles.column}>
                    <div className={`${styles.row} ${styles.marginBottom8}`}>
                        <div
                            className={`${styles.column} ${styles.paddingSecondColumn}`}
                        >
                            <div className={styles.column}>
                                <label className={styles.titleField}>
                                    Nome da tag
                                </label>
                                <TextField
                                    value={name}
                                    onChange={(event) =>
                                        setName(event.target.value)
                                    }
                                    classes={{ root: classes.textField }}
                                    size="small"
                                    variant="outlined"
                                    disabled={globalLoading}
                                />
                            </div>
                            <label className={styles.titleField}>
                                *Obs: Use letras minúsculas, sem espaços ou
                                caracteres especiais
                            </label>
                        </div>
                        <div className={styles.row}>
                            <label
                                className={`${styles.titleField} ${styles.paddingTopAling}`}
                            >
                                Tag Principal
                            </label>
                            <div className={styles.checkBoxWrapper}>
                                <GreenCheckbox
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >,
                                        checked: boolean
                                    ) => onChangeMajor(checked)}
                                    checked={isMajor}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${styles.column} ${styles.paddingSecondColumn} ${styles.marginBottom8}`}
                    >
                        <label className={styles.titleField}>Título</label>
                        <TextField
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            classes={{
                                root: classes.textField,
                            }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                    <div
                        className={`${styles.column} ${styles.paddingSecondColumn}`}
                    >
                        <label className={styles.titleField}>Subtítulo</label>
                        <TextField
                            value={subTitle}
                            onChange={(event) =>
                                setSubTitle(event.target.value)
                            }
                            classes={{
                                root: classes.textField,
                            }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.alignEnd}>
                {!globalLoading && (
                    <>
                        <label
                            onClick={onCancelLocal}
                            className={
                                globalStyles
                                    ? styles.cancelBtn
                                    : styles.disabledCancelBtn
                            }
                        >
                            Cancelar
                        </label>
                        <label
                            onClick={onSaveLocal}
                            className={
                                globalStyles
                                    ? styles.saveBtn
                                    : styles.disabledSaveBtn
                            }
                        >
                            Salvar
                        </label>
                    </>
                )}
            </div>
        </div>
    )
}

export default TagForm
