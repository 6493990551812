import { makeStyles } from '@material-ui/core/'
import colors from '../../../values/colors'
const baseTextField: React.CSSProperties = {
    background: colors.whites.two,
    boxSizing: 'border-box',
    borderRadius: 2,
    outline: 'none',
    fontFamily: 'Poppins',
    fontSize: 12,
    letterSpacing: 0.15,
    color: colors.blacks.two,
}

const useStyles = makeStyles({
    cloudUploadIcon: {
        color: colors.blacks.three,
        cursor: 'pointer',
    },
    closeIcon: {
        color: colors.blacks.three,
        cursor: 'pointer',
    },
    textField: {
        ...baseTextField,
        width: 500,
        marginRight: 24,
        '& > *': {
            borderRadius: 2,
        },
    },
    textLinkField: {
        ...baseTextField,
        width: '100%',
        marginRight: 24,
        '& > *': {
            borderRadius: 2,
        },
    },
    textDescriptionField: {
        ...baseTextField,
        fontSize: 14,
        width: '100%',
        borderColor: colors.greys.four,
    },
    autoCompleteTags: {
        ...baseTextField,
        width: 500,
        fontSize: 14,
    },
    circularProgress: {
        color: colors.blacks.one,
    },
})

export default useStyles
