import { makeStyles } from '@material-ui/core/'
import colors from '../../values/colors'
import globalStyles from '../../values/globalStyles'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        paddingRight: 34,
        paddingLeft: 32,
    },
    actionButton: {
        backgroundColor: colors.purple,
        marginBottom: 24,
        '&:hover': {
            backgroundColor: colors.purple,
        },
    },
    addIcon: {
        color: colors.white,
    },
    tableTitle: {
        fontFamily: 'Poppins',
        fontSize: 14,
        letterSpacing: -0.24,
        color: colors.blacks.three,
    },
    tableText: {
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        fontSize: 12,
        letterSpacing: -0.24,
        color: colors.blacks.one,
    },
    editBtn: {
        color: colors.blues.one,
        cursor: 'pointer',
    },
    activeInactiveBtn: {
        color: colors.greens.one,
    },
    playBtn: {
        cursor: 'pointer',
    },
    pagination: {
        '& > *': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 12,
            letterSpacing: -0.24,
            color: '#AAAAAA',
        },
    },
    circularProgress: {
        alignSelf: 'center',
        color: colors.blacks.one,
    },
    searchField: {
        ...globalStyles.searchInput,
        marginRight: 24,
    },
    selectField: {
        width: 233,
        background: colors.whites.one,
        borderRadius: 5,
        '& > *': {
            fontFamily: 'Poppins',
            fontSize: 14,
            letterSpacing: -0.24,
            color: colors.blacks.three,
        },
    },
})

export default useStyles
