import React from 'react'
import { useLocation } from 'react-router-dom'
import styles from './styles.module.css'
import { Option } from '../../../types/View'

type Props = {
    options: Array<Option>
}

const SideBar: React.FC<Props> = ({ options }) => {
    const location = useLocation()

    const _renderOption = (opt: Option) => {
        if (location.pathname === opt.pathName) {
            return (
                <div
                    key={opt.pathName}
                    onClick={opt.onClick}
                    className={styles.activeOption}
                >
                    <label className={styles.activeTitleOption}>
                        {opt.title}
                    </label>
                </div>
            )
        }
        return (
            <div
                key={opt.pathName}
                onClick={opt.onClick}
                className={styles.simpleOption}
            >
                <label className={styles.simpleTitleOption}>{opt.title}</label>
            </div>
        )
    }
    return (
        <div className={styles.sideBarContainer}>
            <div className={styles.header}>
                <label className={styles.logoTypography}>Namastê</label>
            </div>
            <div className={styles.centralize}>
                <div className={styles.divider} />
            </div>
            <div className={styles.body}>{options.map(_renderOption)}</div>
        </div>
    )
}

export default SideBar
