export default {
    background: '#F7F5F9',
    backgroundBlue: '#4E77C1',
    white: '#FFFFFF',
    purple: '#B772B9',
    red: '#EA4335',
    purples: {
        one: '#B772B9',
    },
    whites: {
        one: '#EAEAEA',
        two: '#F9F9F9',
    },
    greys: {
        three: '##787878',
        four: '#BFBDB6',
        two: '#bababa33',
    },
    greens: {
        one: '#36B37E',
    },
    blues: {
        one: '#4E77C1',
    },
    blacks: {
        one: '#353535',
        two: '#515151',
        three: '#787878',
        four: '#5E5E64',
    },
    yellows: {
        one: '#FFAB00',
    },
}
