import React from 'react'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import moment from 'moment'

import useStyles from './muiStyles'
import { User } from '../../../../types/User'
import { icons } from '../../../../assets'

type Props = {
    user: User
}

const AccountInfo: React.FC<Props> = ({ user }) => {
    const muiStyles = useStyles()

    return (
        <Paper className={muiStyles.paper}>
            <Avatar src={user.photoUrl} className={muiStyles.avatar} />
            <div className={muiStyles.infoContainer}>
                <h1 className={muiStyles.title}>Informações da conta</h1>
                <div className={muiStyles.date}>
                    <CalendarIcon className={muiStyles.dateIcon} />
                    {moment().format('[Membro desde:] DD [de] MMMM [de] YYYY')}
                </div>
                <div className={muiStyles.premium}>
                    Usuário Premium{' '}
                    <img
                        src={icons.yellow_crow}
                        className={muiStyles.crow}
                        alt="Coroa"
                    />
                </div>
            </div>
        </Paper>
    )
}

export default AccountInfo
