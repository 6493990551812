import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import EqualizerIcon from '@material-ui/icons/Equalizer'

import { UserProgress } from '../../../../types/User'
import useStyles from './muiStyles'

type StatisticItemProps = { label: string; value: string }

const StatisticItem: React.FC<StatisticItemProps> = ({ label, value }) => {
    const muiStyles = useStyles()

    return (
        <div className={muiStyles.itemContainer}>
            <div className={muiStyles.itemLabel}>{label}</div>
            <div className={muiStyles.itemValue}>{value}</div>
        </div>
    )
}

type Props = {
    userProgress?: UserProgress
}

const Statistics: React.FC<Props> = ({ userProgress }) => {
    const muiStyles = useStyles()

    const [consecutiveDays, setConsecutiveDays] = useState('-')
    const [consecutiveDaysBest, setConsecutiveDaysBest] = useState('-')
    const [total, setTotal] = useState('-')
    const [daily, setDaily] = useState('-')
    const [sessionDuration, setSessionDuration] = useState('-')
    const [mostLongerSession, setMostLongerSession] = useState('-')
    const [breath, setBreath] = useState('-')

    const getDataAndSetState = async ({
        consecutiveDays = 0,
        consecutiveDaysBest = 0,
        days,
        breathTimeTotalInSeconds = 0,
        timeTotalInSeconds = 0,
    }: UserProgress) => {
        setConsecutiveDays(consecutiveDays.toString())
        setConsecutiveDaysBest(consecutiveDaysBest.toString())

        let dailyTotalSeconds = 0
        let validSessions = days.length
        let maxSession = 0

        days.forEach((p) => {
            Object.entries(p).forEach(([k, v]) => {
                if (v > maxSession) maxSession = v
                dailyTotalSeconds += v
            })
        })

        const totalMinutes = Math.round(timeTotalInSeconds / 60)
        const totalMonthMinutes = Math.round(dailyTotalSeconds / 60)
        const daily = Math.ceil(totalMonthMinutes / 60)

        const session =
            totalMinutes === 0
                ? 0
                : Math.ceil(totalMonthMinutes / validSessions)

        setTotal(totalMinutes ? `${totalMinutes} min` : '-')
        setDaily(daily ? `${daily} min` : '-')
        setSessionDuration(session ? `${session} min` : '-')
        setMostLongerSession(
            maxSession ? `${Math.ceil(maxSession / 60).toString()} min` : '-'
        )

        setBreath(`${Math.round(breathTimeTotalInSeconds / 60).toString()} min`)
    }

    useEffect(() => {
        if (!userProgress) {
            setConsecutiveDays('-')
            setConsecutiveDaysBest('-')
            setTotal('-')
            setDaily('-')
            setSessionDuration('-')
            setMostLongerSession('-')
            setBreath('-')
        } else {
            getDataAndSetState(userProgress)
        }
    }, [userProgress])

    return (
        <Paper className={muiStyles.paper}>
            <h1 className={muiStyles.title}>
                <EqualizerIcon className={muiStyles.titleIcon} /> Estatísticas
            </h1>
            <StatisticItem
                label="Dias consecutivos - atual"
                value={consecutiveDays}
            />
            <StatisticItem
                label="Dias consecutivos - melhor"
                value={consecutiveDaysBest}
            />
            <StatisticItem label="Total" value={total} />
            <StatisticItem label="Média diária" value={daily} />
            <StatisticItem
                label="Duração média da sessão"
                value={sessionDuration}
            />
            <StatisticItem
                label="Sessão de duração mais longa"
                value={mostLongerSession}
            />
            <StatisticItem label="Respiração" value={breath} />
        </Paper>
    )
}

export default Statistics
