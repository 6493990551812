import { makeStyles } from '@material-ui/core/'
import colors from '../../values/colors'
import globalStyles from '../../values/globalStyles'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        paddingRight: 34,
        paddingLeft: 32,
    },
    tableBody: {},
    tableRow: {},
    actionButton: {
        backgroundColor: colors.purple,
        marginBottom: 24,
        '&:hover': {
            backgroundColor: colors.purple,
        },
    },
    addIcon: {
        color: colors.white,
    },
    tableTitle: {
        fontFamily: 'Poppins',
        fontSize: 14,
        letterSpacing: -0.24,
        color: colors.blacks.three,
    },
    tableActive: {
        fontFamily: 'Poppins',
        fontSize: 14,
        letterSpacing: -0.24,
        color: colors.blacks.three,
    },
    tableText: {
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        fontSize: 12,
        letterSpacing: -0.24,
        color: colors.blacks.one,
    },
    editBtn: {
        color: colors.blues.one,
        cursor: 'pointer',
    },
    activeInactiveBtn: {
        color: colors.greens.one,
    },
    pagination: {
        '& > *': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 12,
            letterSpacing: -0.24,
            color: '#AAAAAA',
        },
    },
    circularProgress: {
        alignSelf: 'center',
        color: colors.blacks.one,
    },
    searchField: {
        ...globalStyles.searchInput,
    },
    button: {
        backgroundColor: colors.backgroundBlue,
        marginRight: 10,
        '& > *': {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 1.25,
            textTransform: 'uppercase',
            color: colors.white,
            height: 36,
        },
        '&:hover': {
            backgroundColor: colors.backgroundBlue,
            opacity: 0.8,
        },
    },
})

export default useStyles
