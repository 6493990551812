import React, { useState, useContext, useEffect } from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Autocomplete, {
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
} from '@material-ui/lab/Autocomplete'
import { CircularProgress, Select } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import firebase from 'firebase'
import { Meditation, CreateMeditationPack } from '../../../types/Meditations'
import { ImageUploader, AudioUploader } from '../../../components'
import { TextField, TextareaAutosize } from '@material-ui/core'
import { FileType } from '../../../types/Files'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../../context'
import globalStyles from '../../../values/globalStyles'

type Props = {
    meditation?: Meditation
    onCancel: () => void
    showList: () => void
    tags: string[]
}

const MeditationForm: React.FC<Props> = ({
    meditation,
    onCancel,
    showList,
    tags,
}) => {
    const classes = useStyles()
    const [imageSrc, setImageSrc] = useState<FileType>()
    const [docId] = useState<string>(
        meditation?.docId || `${new Date().getTime()}`
    )
    const [audioSrc, setAudioSrc] = useState<FileType>()
    const [title, setTitle] = useState<string>(meditation?.title || '')
    const [imageUrl] = useState<string>(meditation?.imageUrl || '')
    const [time, setTime] = useState<number>(meditation?.time || 0)
    const [selectedTags, setSelectedTags] = useState<string[]>(
        meditation?.tags || []
    )
    const [description, setDescription] = useState<string>(
        meditation?.description || ''
    )
    const [meditationAudioUrl, setMeditationAudioUrl] = useState<string>('')
    const [meditationImageUrl, setMeditationImageUrl] = useState<string>('')
    const [imgInputRef] = React.useState<React.RefObject<HTMLInputElement>>(
        React.createRef()
    )
    const [audioInputRef] = React.useState<React.RefObject<HTMLInputElement>>(
        React.createRef()
    )

    const { showMessage, setGlobalLoading, globalLoading } = useContext(
        ApplicationContext
    )

    const triggerImgInput = () => {
        if (imgInputRef.current && !globalLoading) {
            imgInputRef.current.click()
        }
    }

    const triggerAudioInput = () => {
        if (audioInputRef.current && !globalLoading) {
            audioInputRef.current.click()
        }
    }

    const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!globalLoading) {
            if (e.target.files && e.target.files.length) {
                const arrFiles = Array.from(e.target.files)
                const files = arrFiles.map((file, index) => {
                    const src = window.URL.createObjectURL(file)
                    return { file, src }
                })
                setImageSrc(files[files.length - 1])
            }
        }
    }

    const onChangeAudio = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!globalLoading) {
            if (e.target.files && e.target.files.length) {
                const arrFiles = Array.from(e.target.files)
                const files = arrFiles.map((file, index) => {
                    const src = window.URL.createObjectURL(file)
                    return { file, src }
                })
                setAudioSrc(files[files.length - 1])
            }
        }
    }

    const [canChooseBackground, setCanChooseBackground] = useState<boolean>(
        !!meditation?.canChooseBackground
    )

    const [premium, setPremium] = useState<boolean>(!!meditation?.premium)

    const handleSelectPremium = (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode
    ) => {
        const value = event.target.value as string
        setPremium(value !== 'Sim')
    }

    const handleSelectBackground = (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode
    ) => {
        const value = event.target.value as string
        setCanChooseBackground(value === 'Sim')
    }

    const clearFields = () => {
        setImageSrc(undefined)
        setAudioSrc(undefined)
        setTitle('')
        setTime(0)
        setSelectedTags([])
        setDescription('')
        setMeditationAudioUrl('')
        setMeditationImageUrl('')
    }

    const onCancelLocal = () => {
        clearFields()
        onCancel()
    }

    const uploadFile = (
        file: FileType,
        type: 'images' | 'meditacoes',
        resolve?: any,
        reject?: any
    ) => {
        const storageRef = firebase.storage().ref()
        const fileName = meditation ? meditation.docId : docId // if have meditation, the user is editing
        const uploadTask = storageRef
            .child(`${type}/${fileName}`) //padronize name to aways override file
            .put(file.file)

        uploadTask.on(
            'state_changed',
            function (snapshot) {},
            function (error) {
                showMessage(error.message)
                if (reject) reject()
            },
            function () {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        if (type === 'images') {
                            setMeditationImageUrl(downloadURL)
                        } else {
                            setMeditationAudioUrl(downloadURL)
                        }
                        if (resolve) resolve()
                    })
            }
        )
    }

    const [imageUpload, setImageUpload] = useState<boolean>(false)
    const [audioUpload, setAudioUpload] = useState<boolean>(false)

    const onEditMeditation = (meditationPack: CreateMeditationPack) => {
        setGlobalLoading(true)

        //If no changes in image or audio, set infos here
        if (!meditationPack.audioSrc && !meditationPack.imageSrc) {
            firebase
                .firestore()
                .collection('meditation')
                .doc(meditationPack?.docId)
                .set(
                    {
                        updatedAt: firebase.firestore.Timestamp.now().toDate(),
                        active: true,
                        title,
                        description,
                        canChooseBackground,
                        premium,
                        time,
                        tags: selectedTags,
                    },
                    { merge: true }
                )
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A nova meditação foi alterada')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }

        if (meditationPack.imageSrc) {
            uploadFile(meditationPack.imageSrc, 'images')
            setImageUpload(true)
        }
        if (meditationPack.audioSrc) {
            uploadFile(meditationPack.audioSrc, 'meditacoes')
            setAudioUpload(true)
        }
    }

    useEffect(() => {
        let fbDocSetter = firebase
            .firestore()
            .collection('meditation')
            .doc(docId)
        const onSuccessUpdate = () => {
            clearFields()
            showList()
            showMessage('A nova meditação foi alterada')
        }

        if (audioUpload && imageUpload) {
            // For update both
            if (
                !!meditationAudioUrl &&
                meditationAudioUrl !== '' &&
                !!meditationImageUrl &&
                meditationImageUrl !== '' &&
                !!title &&
                title !== '' &&
                !!description &&
                description !== '' &&
                !!time &&
                time !== 0 &&
                !!selectedTags &&
                selectedTags.length !== 0
            ) {
                fbDocSetter
                    .set(
                        {
                            audioVoice: meditationAudioUrl,
                            imageUrl: meditationImageUrl,
                            updatedAt: firebase.firestore.Timestamp.now().toDate(),
                            active: true,
                            title,
                            description,
                            time,
                            canChooseBackground,
                            premium,
                            tags: selectedTags,
                        },
                        { merge: true }
                    )
                    .then(onSuccessUpdate)
                    .catch((error) => showMessage(error.message))
                    .finally(() => setGlobalLoading(false))
            }
        }
        if (audioUpload && !imageUpload) {
            console.log('Upload Audio')
            // For update only audio
            if (
                !!meditationAudioUrl &&
                meditationAudioUrl !== '' &&
                !!title &&
                title !== '' &&
                !!description &&
                description !== '' &&
                !!time &&
                time !== 0 &&
                !!selectedTags &&
                selectedTags.length !== 0
            ) {
                fbDocSetter
                    .set(
                        {
                            audioVoice: meditationAudioUrl,
                            updatedAt: firebase.firestore.Timestamp.now().toDate(),
                            active: true,
                            title,
                            description,
                            time,
                            tags: selectedTags,
                        },
                        { merge: true }
                    )
                    .then(onSuccessUpdate)
                    .catch((error) => showMessage(error.message))
                    .finally(() => setGlobalLoading(false))
            }
        }

        if (!audioUpload && imageUpload) {
            console.log('Upload Image')
            // For update only image
            if (
                !!meditationImageUrl &&
                meditationImageUrl !== '' &&
                !!title &&
                title !== '' &&
                !!description &&
                description !== '' &&
                !!time &&
                time !== 0 &&
                !!selectedTags &&
                selectedTags.length !== 0
            ) {
                fbDocSetter
                    .set(
                        {
                            imageUrl: meditationImageUrl,
                            updatedAt: firebase.firestore.Timestamp.now().toDate(),
                            active: true,
                            title,
                            description,
                            time,
                            canChooseBackground,
                            premium,
                            tags: selectedTags,
                        },
                        { merge: true }
                    )
                    .then(onSuccessUpdate)
                    .catch((error) => showMessage(error.message))
                    .finally(() => setGlobalLoading(false))
            }
        }
    }, [
        audioUpload,
        description,
        docId,
        imageUpload,
        meditationAudioUrl,
        meditationImageUrl,
        selectedTags,
        setGlobalLoading,
        showList,
        showMessage,
        time,
        title,
        canChooseBackground,
        premium,
    ])

    const onSaveLocal = () => {
        if (globalLoading) return

        if (!title) {
            showMessage('Digite o título da meditação')
            return
        }

        if (!time || time === 0) {
            showMessage('Digite a duração máxima do audio em segundos')
            return
        }

        if (!tags) {
            showMessage('Escolha as tags da meditação')
            return
        }
        if (!description) {
            showMessage('Digite uma descriação para a meditação')
            return
        }

        onEditMeditation({
            ...meditation,
            imageSrc,
            audioSrc,
            title,
            description,
            tags: selectedTags,
            time,
            canChooseBackground,
            premium,
        })
    }

    const getImgSource = () => {
        if (imageSrc) {
            return imageSrc?.src
        }

        if (imageUrl) {
            return imageUrl
        }

        return ''
    }

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <ImageUploader
                    children={() => (
                        <div className={styles.imageUploadBtn}>
                            {!!imageSrc || imageUrl !== '' ? (
                                <img
                                    src={getImgSource()}
                                    alt="imagem"
                                    className={styles.imgPreview}
                                />
                            ) : (
                                <>
                                    <CloudUploadIcon
                                        className={classes.cloudUploadIcon}
                                    />
                                    <label
                                        className={styles.imageUploadBtnLabel}
                                    >
                                        imagem
                                    </label>
                                </>
                            )}
                        </div>
                    )}
                    onChange={onChangeAvatar}
                    inputRef={imgInputRef}
                    triggerInput={triggerImgInput}
                />
                {(!!imageSrc || imageUrl !== '') && (
                    <label className={styles.imageRemoveBtnLabel}>
                        remover imagem
                    </label>
                )}
            </div>
            <div className={`${styles.column} ${styles.paddingSecondColumn}`}>
                <div className={`${styles.row} ${styles.marginBottom8}`}>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Título</label>
                        <TextField
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            classes={{ root: classes.textField }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={styles.column}>
                        <label className={styles.titleField}>
                            Tempo (segundos)
                        </label>
                        <TextField
                            value={time}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event
                                setTime(parseInt(value))
                            }}
                            classes={{ root: classes.textTimeField }}
                            size="small"
                            variant="outlined"
                            type={'number'}
                            disabled={globalLoading}
                        />
                    </div>
                </div>
                <div className={styles.column}>
                    <label className={styles.titleField}>Tags</label>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        classes={{ root: classes.autoCompleteTags }}
                        options={tags}
                        getOptionLabel={(option) => option}
                        size={'small'}
                        defaultValue={[...selectedTags]}
                        onChange={(
                            event: React.ChangeEvent<{}>,
                            value: string[],
                            reason: AutocompleteChangeReason,
                            details?:
                                | AutocompleteChangeDetails<string>
                                | undefined
                        ) => {
                            setSelectedTags(value)
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                        )}
                        disabled={globalLoading}
                    />
                </div>
                <div className={`${styles.column} padding-top-8`}>
                    <label className={styles.titleField}>Descrição</label>
                    <TextareaAutosize
                        value={description}
                        onChange={(
                            event: React.ChangeEvent<HTMLTextAreaElement>
                        ) => setDescription(event.target.value)}
                        className={classes.textDescriptionField}
                        rowsMin={7}
                        disabled={globalLoading}
                    />
                    <div className={`row`}>
                        <div className={`column`}>
                            <label className={styles.titleField}>
                                Permitir música de fundo
                            </label>
                            <Select
                                value={canChooseBackground ? 'Sim' : 'Não'}
                                onChange={handleSelectBackground}
                                className={classes.selectField}
                                variant={'outlined'}
                            >
                                <option
                                    className={styles.selectOptions}
                                    value={'Não'}
                                >
                                    Não
                                </option>
                                <option
                                    className={styles.selectOptions}
                                    value={'Sim'}
                                >
                                    Sim
                                </option>
                            </Select>
                        </div>
                        <div className={`column`}>
                            <label className={styles.titleField}>
                                Meditação gratuita
                            </label>
                            <Select
                                value={premium ? 'Não' : 'Sim'}
                                onChange={handleSelectPremium}
                                className={classes.selectField}
                                variant={'outlined'}
                            >
                                <option
                                    className={styles.selectOptions}
                                    value={'Não'}
                                >
                                    Não
                                </option>
                                <option
                                    className={styles.selectOptions}
                                    value={'Sim'}
                                >
                                    Sim
                                </option>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={styles.alignEnd}>
                    {globalLoading ? (
                        <CircularProgress
                            size={20}
                            classes={{ root: classes.circularProgress }}
                        />
                    ) : (
                        <>
                            <label
                                onClick={onCancelLocal}
                                className={
                                    globalStyles
                                        ? styles.cancelBtn
                                        : styles.disabledCancelBtn
                                }
                            >
                                Cancelar
                            </label>
                            <label
                                onClick={onSaveLocal}
                                className={
                                    globalStyles
                                        ? styles.saveBtn
                                        : styles.disabledSaveBtn
                                }
                            >
                                Salvar
                            </label>
                        </>
                    )}
                </div>
            </div>
            <div className={`${styles.column} ${styles.paddingLastColumn}`}>
                <div className={styles.row}>
                    <AudioUploader
                        children={() => (
                            <div className={styles.audioUploadBtn}>
                                <CloudUploadIcon
                                    className={classes.cloudUploadIcon}
                                />
                                <label className={styles.audioUploadBtnLabel}>
                                    Áudio
                                </label>
                            </div>
                        )}
                        onChange={onChangeAudio}
                        inputRef={audioInputRef}
                        triggerInput={triggerAudioInput}
                    />
                    {!!audioSrc && (
                        <div className={styles.row}>
                            <label className={styles.audioUploadBtnLabel}>
                                {audioSrc.file.name}
                            </label>
                            <CloseIcon
                                className={classes.closeIcon}
                                onClick={() => setAudioSrc(undefined)}
                                fontSize={'small'}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MeditationForm
