import React, { useState, useContext, useEffect } from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { CircularProgress } from '@material-ui/core'
import firebase from 'firebase'
import {
    Notification,
    CreateNotificationPack,
} from '../../../types/Notifications'
import { ImageUploader } from '../../../components'
import { TextField, TextareaAutosize } from '@material-ui/core'
import { FileType } from '../../../types/Files'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../../context'
import globalStyles from '../../../values/globalStyles'
import Autocomplete, {
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
} from '@material-ui/lab/Autocomplete'

type Props = {
    notification?: Notification
    onCancel: () => void
    showList: () => void
}

const NotificationForm: React.FC<Props> = ({
    notification,
    onCancel,
    showList,
}) => {
    const classes = useStyles()
    const [imageSrc, setImageSrc] = useState<FileType>()
    const [docId] = useState<string>(
        notification?.docId || `${new Date().getTime()}`
    )
    const [title, setTitle] = useState<string>(notification?.title || '')
    const [description, setDescription] = useState<string>(
        notification?.description || ''
    )
    const [imageUrl] = useState<string>(notification?.image || '')
    const [topics, setTopics] = useState<string[]>([])
    const [selectedTopics, setSelectedTopics] = useState<string[]>(
        notification?.topics || []
    )
    const [link, setLink] = useState<string>(notification?.link || '')

    const [imgInputRef] = React.useState<React.RefObject<HTMLInputElement>>(
        React.createRef()
    )

    const { showMessage, setGlobalLoading, globalLoading } = useContext(
        ApplicationContext
    )

    const triggerImgInput = () => {
        if (imgInputRef.current && !globalLoading) {
            imgInputRef.current.click()
        }
    }

    const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!globalLoading) {
            if (e.target.files && e.target.files.length) {
                const arrFiles = Array.from(e.target.files)
                const files = arrFiles.map((file, index) => {
                    const src = window.URL.createObjectURL(file)
                    return { file, src }
                })
                setImageSrc(files[files.length - 1])
            }
        }
    }

    useEffect(() => {
        if (topics.length === 0) {
            firebase
                .firestore()
                .collection('tags')
                .onSnapshot((snapshot) => {
                    const tagsBuilder: any[] = []
                    snapshot.forEach((doc) => {
                        if (doc.data().active) {
                            tagsBuilder.push(doc.data().tag)
                        }
                    })
                    setTopics(tagsBuilder)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const clearFields = () => {
        setImageSrc(undefined)
        setTitle('')
        setDescription('')
        setLink('')
        setTopics([])
    }

    const onCancelLocal = () => {
        clearFields()
        onCancel()
    }

    const uploadFile = (file: FileType) => {
        const storageRef = firebase.storage().ref()
        const fileName = notification ? notification.docId : docId // if have meditation, the user is editing
        const uploadTask = storageRef
            .child(`notifications/${fileName}`) //padronize name to aways override file
            .put(file.file)

        uploadTask.on(
            'state_changed',
            function (snapshot) {},
            function (error) {
                showMessage(error.message)
            },
            function () {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        firebase
                            .firestore()
                            .collection('notifications')
                            .doc(docId)
                            .set({
                                timestamp: firebase.firestore.Timestamp.now(),
                                show: true,
                                title,
                                description,
                                link: link ? link : null,
                                topics:
                                    selectedTopics.length > 0
                                        ? selectedTopics
                                        : null,
                                image: downloadURL,
                            })
                            .then(() => {
                                clearFields()
                                showList()
                                showMessage('A nova meditação foi adicionada')
                            })
                            .catch((error) => showMessage(error.message))
                            .finally(() => setGlobalLoading(false))
                    })
            }
        )
    }

    const onSend = (notificationPack: CreateNotificationPack) => {
        setGlobalLoading(true)
        if (notificationPack.imageSrc) {
            uploadFile(notificationPack.imageSrc!)
        } else {
            firebase
                .firestore()
                .collection('notifications')
                .doc(docId)
                .set(notificationPack)
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A nova meditação foi adicionada')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }
    }

    const onSendLocal = () => {
        if (globalLoading) return

        if (!title || title.trim() === '') {
            showMessage('Digite o título da respiração')
            return
        }

        if (!description || description.trim() === '') {
            showMessage('Digite uma descriação para a respiração')
            return
        }

        if (!selectedTopics || selectedTopics.length === 0) {
            showMessage('Escolha pelo menos uma tag para a notificação')
            return
        }

        const imgObjectToPack = imageSrc ? { imageSrc } : {}

        onSend({
            timestamp: firebase.firestore.Timestamp.now(),
            show: true,
            title,
            description,
            link: link ? link : null,
            topics: selectedTopics.length > 0 ? selectedTopics : null,
            ...imgObjectToPack,
        })
    }

    const getImgSource = () => {
        if (imageSrc) {
            return imageSrc?.src
        }

        if (imageUrl) {
            return imageUrl
        }

        return ''
    }

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <ImageUploader
                    children={() => (
                        <div className={styles.imageUploadBtn}>
                            {!!imageSrc || imageUrl ? (
                                <img
                                    src={getImgSource()}
                                    alt="imagem"
                                    className={styles.imgPreview}
                                />
                            ) : (
                                <>
                                    <CloudUploadIcon
                                        className={classes.cloudUploadIcon}
                                    />
                                    <label
                                        className={styles.imageUploadBtnLabel}
                                    >
                                        imagem
                                    </label>
                                </>
                            )}
                        </div>
                    )}
                    onChange={onChangeAvatar}
                    inputRef={imgInputRef}
                    triggerInput={triggerImgInput}
                />
                {(!!imageSrc || imageUrl) && (
                    <label className={styles.imageRemoveBtnLabel}>
                        remover imagem
                    </label>
                )}
            </div>
            <div className={`${styles.column} ${styles.paddingSecondColumn}`}>
                <div className={styles.row}>
                    <div className={`${styles.column} ${styles.marginBottom8}`}>
                        <label className={styles.titleField}>Título</label>
                        <TextField
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            classes={{ root: classes.textField }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={`${styles.column} ${styles.marginBottom8}`}>
                        <label className={styles.titleField}>
                            Tags (Escolha a tag geral para enviar para todos.
                            Obs: Máximo de 3 tags)
                        </label>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            classes={{ root: classes.autoCompleteTags }}
                            options={['geral', ...topics]}
                            getOptionLabel={(option) => option}
                            size={'small'}
                            defaultValue={[...selectedTopics]}
                            value={[...selectedTopics]}
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                value: string[],
                                reason: AutocompleteChangeReason,
                                details?:
                                    | AutocompleteChangeDetails<string>
                                    | undefined
                            ) => {
                                if (
                                    selectedTopics.length === 3 &&
                                    value.length > selectedTopics.length
                                ) {
                                    showMessage(
                                        'O envio de notificações é limitado a um total 3 tags'
                                    )
                                } else {
                                    setSelectedTopics(value)
                                }
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                            disabled={globalLoading}
                        />
                    </div>
                </div>
                <div className={`${styles.column} ${styles.marginBottom8}`}>
                    <label className={styles.titleField}>Link</label>
                    <TextField
                        value={link}
                        onChange={(event) => setLink(event.target.value)}
                        classes={{ root: classes.textLinkField }}
                        size="small"
                        variant="outlined"
                        disabled={globalLoading}
                    />
                </div>
                <div className={styles.column}>
                    <label className={styles.titleField}>Descrição</label>
                    <TextareaAutosize
                        value={description}
                        onChange={(
                            event: React.ChangeEvent<HTMLTextAreaElement>
                        ) => setDescription(event.target.value)}
                        className={classes.textDescriptionField}
                        rowsMin={7}
                        disabled={globalLoading}
                    />
                </div>
                <div className={styles.alignEnd}>
                    {globalLoading ? (
                        <CircularProgress
                            size={20}
                            classes={{ root: classes.circularProgress }}
                        />
                    ) : (
                        <>
                            <label
                                onClick={onCancelLocal}
                                className={
                                    globalStyles
                                        ? styles.cancelBtn
                                        : styles.disabledCancelBtn
                                }
                            >
                                Cancelar
                            </label>
                            <label
                                onClick={onSendLocal}
                                className={
                                    globalStyles
                                        ? styles.saveBtn
                                        : styles.disabledSaveBtn
                                }
                            >
                                Salvar
                            </label>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NotificationForm
