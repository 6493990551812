import { makeStyles } from '@material-ui/core'
import colors from '../../../../values/colors'

const muiStyles = makeStyles({
    paper: {
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 32,
    },
    section: {
        marginBottom: '1rem',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1rem',
        margin: 0,
        marginBottom: '1rem',
        letterSpacing: '1.5px',
        color: colors.blacks.two,
        textTransform: 'uppercase',
    },
    titleIcon: {
        marginRight: '0.5rem',
        color: colors.blacks.two,
    },
    itemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.25rem 0',
        marginBottom: '0.25rem',
        borderWidth: '0 0 0.5px 0',
        borderStyle: 'solid',
        borderColor: '#51515133',
    },
    itemLabel: {
        marginRight: '2rem',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '0.9rem',
        letterSpacing: '0.8px',
        color: colors.blacks.two,
    },
    itemValue: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.8rem',
        color: colors.purples.one,
    },
})

export default muiStyles
