import { makeStyles } from '@material-ui/core'

import colors from '../../../../values/colors'

const muiStyles = makeStyles({
    paper: {
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 32,
        marginRight: 32,
    },
    section: {
        marginBottom: '1rem',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1rem',
        margin: 0,
        letterSpacing: '0.996px',
        marginBottom: '0.5rem',
        color: colors.blacks.two,
        textTransform: 'uppercase',
    },
    titleIcon: {
        marginRight: '0.5rem',
        color: colors.blacks.two,
    },

    legend: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: '160%',
        marginBottom: '0.5rem',
        color: colors.blacks.four,
    },
    textField: {
        display: 'flex',
    },
    chipContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    chip: {
        marginRight: '0.5rem',
        marginBottom: '0.5rem',
    },
})

export default muiStyles
