import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { firestore } from 'firebase'
import moment from 'moment'

import styles from './styles.module.css'
import { User, UserProgress } from '../../types/User'
import { BasePage } from '../../container'
import { LinearProgress } from '@material-ui/core'
import AccountInfo from './layouts/AccountInfo'
import UserData from './layouts/UserData'
import Statistics from './layouts/Statistics'

const UserDetailPage: React.FC = () => {
    const {
        state: { user },
    } = useLocation<{ user: User }>()

    const [loading, setLoading] = useState(true)
    const [userProgress, setUserProgress] = useState<UserProgress>()

    const getUserProgress = useCallback(async () => {
        const progressSnapshot = await firestore()
            .collection('user_progress')
            .doc(user.id)
            .get()

        const monthDoc = await progressSnapshot.ref
            .collection('days')
            .doc(moment().format('YYYYMM'))
            .get()

        const monthData = monthDoc.data()

        const days: Array<{ [key: string]: number }> =
            // @ts-ignore
            Object.entries(monthData).map(([k, v], index) => ({ [k]: v }))

        const progress = {
            id: progressSnapshot.id,
            ...progressSnapshot.data(),
            days,
        } as UserProgress

        setUserProgress(progress)
    }, [user.id])

    useEffect(() => {
        setLoading(true)
        getUserProgress().finally(() => setLoading(false))
    }, [getUserProgress])

    return (
        <BasePage>
            {loading && <LinearProgress />}
            <div className={styles.container}>
                <section className={styles.section}>
                    <AccountInfo user={user} />
                    <UserData user={user} />
                </section>
                <section className={styles.section}>
                    <Statistics userProgress={userProgress} />
                </section>
            </div>
        </BasePage>
    )
}

export default UserDetailPage
