import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import 'moment/locale/pt-br'

import * as serviceWorker from './serviceWorker'
import { AppRouter } from './router'
import { initFirebase } from './config'
import './index.css'
import theme from './muiConfig/theme'

initFirebase()

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <AppRouter />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
