import React, { useState, useEffect, useContext } from 'react'
import firebase from 'firebase'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Fab,
    TablePagination,
    LinearProgress,
    InputAdornment,
    TextField,
} from '@material-ui/core/'
import moment from 'moment'

import { Phrase } from '../../types/Phrases'
import { BasePage } from '../../container'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../context'
import PhraseItem from './layouts/PhraseItem'
import PhraseForm from './layouts/Form'

const COLLECTION_NAME = 'frontage'
const ORDER_BY = 'createdAt'
const SORTED_BY = 'desc'

const FrontAgePage: React.FC = () => {
    const classes = useStyles()
    const [phrases, setPhrases] = useState<Phrase[]>([])
    const [searchText, setSearchText] = useState<string>('')
    const [rowsPerPage, setRowsPerPage] = useState<number>(25)
    const [page, setPage] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [isNew, setIsNew] = useState<boolean>(false)
    const [isInEdit, setIsInEdit] = useState<boolean>(false)
    const [phraseToEdit, setPhraseToEdit] = useState<Phrase>()

    const { showMessage, globalLoading } = useContext(ApplicationContext)

    useEffect(() => {
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .get()
            .then((snapshot) => {
                setTotal(snapshot.size)
            })
            .catch(() => showMessage('Falha ao pegar o total de items'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew, isInEdit])

    const updatePhrases = (snapshot) => {
        const phraseBuilder: any[] = []
        snapshot.forEach((doc) => {
            let updatedAtIfExists = {}

            if (doc.data().updatedAt) {
                updatedAtIfExists = {
                    updatedAt: moment(
                        doc.data().updatedAt.seconds * 1000
                    ).toDate(),
                }
            }

            phraseBuilder.push({
                docId: doc.id,
                ...doc.data(),
                createdAt: moment(doc.data().createdAt.seconds * 1000).toDate(),
                ...updatedAtIfExists,
            })
        })
        setPhrases(phraseBuilder)
    }

    useEffect(() => {
        setLoading(true)
        const existTextToSearch = !!searchText && searchText !== ''

        if (existTextToSearch) {
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .get()
                .then((snapshot) => {
                    const phrasesBuilder: any[] = []
                    snapshot.forEach((doc) => {
                        if (
                            Object.values(doc.data())
                                .join(',')
                                .toUpperCase()
                                .match(searchText.toUpperCase())
                        ) {
                            let updatedAtIfExists = {}

                            if (doc.data().updatedAt) {
                                updatedAtIfExists = {
                                    updatedAt: moment(
                                        doc.data().updatedAt.seconds * 1000
                                    ).toDate(),
                                }
                            }
                            phrasesBuilder.push({
                                docId: doc.id,
                                ...doc.data(),
                                createdAt: moment(
                                    doc.data().createdAt.seconds * 1000
                                ).toDate(),
                                ...updatedAtIfExists,
                            })
                        }
                    })
                    setPhrases(phrasesBuilder)
                })
                .catch((error) => {
                    console.log(error.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .orderBy(ORDER_BY, SORTED_BY)
                .limit(rowsPerPage)
                .get()
                .then(updatePhrases)
                .finally(() => setLoading(false))
        }
    }, [searchText, rowsPerPage, isNew, isInEdit])

    const [previousCreatedAt, setPreviousCreatedAt] = useState<Date>()

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setPage(newPage)

        let creator = firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .orderBy(ORDER_BY, SORTED_BY)

        if (page < newPage) {
            creator = creator
                .startAfter(phrases[phrases.length - 1][ORDER_BY])
                .limit(rowsPerPage)

            setPreviousCreatedAt(phrases[phrases.length - 1][ORDER_BY])
        } else {
            const endBeforeParam =
                phrases.length !== 0 ? phrases[0][ORDER_BY] : previousCreatedAt

            creator = creator.endBefore(endBeforeParam).limitToLast(rowsPerPage)
        }

        creator

            .get()
            .then(updatePhrases)
            .finally(() => setLoading(false))
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value))
        setLoading(true)
        setPage(0)
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .orderBy(ORDER_BY, SORTED_BY)
            .limit(rowsPerPage)
            .get()
            .then(updatePhrases)
            .finally(() => setLoading(false))
    }

    const handleActiveOrDisable = (checked: boolean, docId: string) => {
        setLoading(true)
        const backUpPhrases = [...phrases]
        const clonePhrases = [...phrases]
        const index = clonePhrases.findIndex((med) => med.docId === docId)
        clonePhrases.splice(index, 1, {
            ...clonePhrases[index],
            active: checked,
        })
        setPhrases(clonePhrases)

        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .doc(docId)
            .set({ active: checked }, { merge: true })
            .then(() => {
                showMessage(`A frase foi ${checked ? '' : 'in'}ativada`)
            })
            .catch((error) => {
                setPhrases(backUpPhrases)
                showMessage(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleEdit = (phraseRow: Phrase) => {
        setPhraseToEdit(phraseRow)
        setIsInEdit(true)
    }

    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    }

    const handleNewBreath = () => {
        setIsNew(true)
    }

    const onCancelPhrase = () => {
        setIsNew(false)
        setIsInEdit(false)
        setPhraseToEdit(undefined)
    }

    const handleDelete = (phrase: Phrase) => {
        setLoading(true)
        const backUpPhrases = [...phrases]
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .doc(phrase.docId)
            .delete()
            .then(() => {
                showMessage(`A frase foi removida`)
            })
            .catch((error) => {
                setPhrases(backUpPhrases)
                showMessage(error.message)
                handleChangePage(null, 1)
            })
            .finally(() => {
                setLoading(false)
            })

        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .orderBy(ORDER_BY, SORTED_BY)
            .limit(rowsPerPage)
            .get()
            .then(updatePhrases)
            .finally(() => setLoading(false))
    }

    return (
        <BasePage>
            {(loading || globalLoading) && <LinearProgress />}
            <div className={styles.container}>
                <Fab
                    onClick={() => handleNewBreath()}
                    classes={{ root: classes.actionButton }}
                    aria-label="add"
                >
                    <AddIcon classes={{ root: classes.addIcon }} />
                </Fab>
                {(isNew || isInEdit) && (
                    <PhraseForm
                        phrase={phraseToEdit}
                        onCancel={onCancelPhrase}
                        showList={() => onCancelPhrase()}
                    />
                )}
                {!isInEdit && (
                    <div className={styles.tableWrapper}>
                        <div className={styles.searchWrapper}>
                            <TextField
                                id="outlined-start-adornment"
                                value={searchText}
                                onChange={handleSearchText}
                                className={classes.searchField}
                                placeholder={'Localizar'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant={'outlined'}
                            />
                        </div>
                        <TableContainer component={Paper}>
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Imagem de fundo
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Frase
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Início
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Final
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Criado em
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Atualizado em
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Ativado
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {phrases.map(
                                        (row: Phrase, index: number) => (
                                            <PhraseItem
                                                row={row}
                                                index={index}
                                                handleDelete={handleDelete}
                                                handleEdit={handleEdit}
                                                handleActiveOrDisable={
                                                    handleActiveOrDisable
                                                }
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={total}
                                className={classes.pagination}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage={'Itens por página:'}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </div>
                )}
            </div>
        </BasePage>
    )
}

export default FrontAgePage
