import React, { useState, useContext, useEffect } from 'react'
import firebase from 'firebase'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloseIcon from '@material-ui/icons/Close'
import { AudioUploader } from '../../../components'
import { TextField } from '@material-ui/core'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../../context'
import globalStyles from '../../../values/globalStyles'
import { Music, CreateMusicPack } from '../../../types/Musics'
import { FileType } from '../../../types/Files'

type Props = {
    music?: Music
    onCancel: () => void
    showList: () => void
}

const TagForm: React.FC<Props> = ({ music, onCancel, showList }) => {
    const classes = useStyles()
    const [docId] = useState<string>(music?.docId || `${new Date().getTime()}`)
    const [title, setTitle] = useState<string>(music?.title || '')
    const [audioUrl, setAudioUrl] = useState<string>('')
    const [audioSrc, setAudioSrc] = useState<FileType>()
    const [audioInputRef] = React.useState<React.RefObject<HTMLInputElement>>(
        React.createRef()
    )

    const triggerAudioInput = () => {
        if (audioInputRef.current && !globalLoading) {
            audioInputRef.current.click()
        }
    }

    const onChangeAudio = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!globalLoading) {
            if (e.target.files && e.target.files.length) {
                const arrFiles = Array.from(e.target.files)
                const files = arrFiles.map((file, index) => {
                    const src = window.URL.createObjectURL(file)
                    return { file, src }
                })
                setAudioSrc(files[0])
            }
        }
    }

    const { showMessage, setGlobalLoading, globalLoading } = useContext(
        ApplicationContext
    )

    const clearFields = () => {
        setTitle('')
    }

    const onCancelLocal = () => {
        clearFields()
        onCancel()
    }

    const uploadFile = (file: FileType) => {
        const storageRef = firebase.storage().ref()
        const uploadTask = storageRef.child(`audio_bg/${docId}`).put(file.file)

        uploadTask.on(
            'state_changed',
            function (snapshot) {},
            function (error) {
                showMessage(error.message)
            },
            function () {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        setAudioUrl(downloadURL)
                    })
            }
        )
    }

    const onSaveTag = (musicPack: CreateMusicPack) => {
        setGlobalLoading(true)
        uploadFile(musicPack.audioSrc!)
    }

    const [needUploadInEdit, setNeedUploadInEdit] = React.useState<boolean>(
        false
    )

    const onEditTag = (musicPackToEdit: CreateMusicPack) => {
        setGlobalLoading(true)

        if (!!musicPackToEdit.audioSrc) {
            setNeedUploadInEdit(true)
            uploadFile(musicPackToEdit.audioSrc)
        } else {
            firebase
                .firestore()
                .collection('background_music')
                .doc(docId)
                .set(musicPackToEdit, { merge: true })
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A música foi alterada')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }
    }

    useEffect(() => {
        if (
            !!title &&
            title !== '' &&
            !!audioUrl &&
            audioUrl !== '' &&
            !music
        ) {
            firebase
                .firestore()
                .collection('background_music')
                .doc(docId)
                .set({
                    createdAt: firebase.firestore.Timestamp.now(),
                    title,
                    active: true,
                    url: audioUrl,
                })
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A música foi adicionada!')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }

        if (
            needUploadInEdit &&
            !!title &&
            title !== '' &&
            !!audioUrl &&
            audioUrl !== '' &&
            !!music
        ) {
            firebase
                .firestore()
                .collection('background_music')
                .doc(docId)
                .set(
                    {
                        updatedAt: firebase.firestore.Timestamp.now(),
                        title,
                        active: true,
                        url: audioUrl,
                    },
                    { merge: true }
                )
                .then(() => {
                    clearFields()
                    showList()
                    showMessage('A música foi alterada!')
                })
                .catch((error) => showMessage(error.message))
                .finally(() => setGlobalLoading(false))
        }
    }, [
        audioUrl,
        docId,
        music,
        needUploadInEdit,
        setGlobalLoading,
        showList,
        showMessage,
        title,
    ])

    const onSaveLocal = () => {
        if (globalLoading) return

        if (!title) {
            showMessage('Digite o título da tag')
            return
        }

        if (!music) {
            if (!audioSrc) {
                showMessage('Escolha o arquivo de áudio que deseja enviar')
                return
            }
            onSaveTag({
                createdAt: firebase.firestore.Timestamp.now().toDate(),
                active: true,
                title,
                audioSrc,
            })
        } else {
            const audioObj = audioSrc ? { audioSrc } : {}
            onEditTag({
                ...music,
                createdAt: firebase.firestore.Timestamp.now().toDate(),
                updatedAt: firebase.firestore.Timestamp.now().toDate(),
                active: true,
                title,
                ...audioObj,
            })
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <label className={styles.titleField}>Título</label>
                    <TextField
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                        classes={{ root: classes.textField }}
                        size="small"
                        variant="outlined"
                        disabled={globalLoading}
                    />
                </div>
                <div className={`${styles.column} ${styles.paddingLastColumn}`}>
                    <AudioUploader
                        children={() => (
                            <div className={styles.audioUploadBtn}>
                                <CloudUploadIcon
                                    className={classes.cloudUploadIcon}
                                />
                                <label className={styles.audioUploadBtnLabel}>
                                    Áudio
                                </label>
                            </div>
                        )}
                        onChange={onChangeAudio}
                        inputRef={audioInputRef}
                        triggerInput={triggerAudioInput}
                    />
                    {!!audioSrc && (
                        <div className={styles.row}>
                            <label className={styles.audioUploadBtnLabel}>
                                {audioSrc.file.name}
                            </label>
                            <CloseIcon
                                className={classes.closeIcon}
                                onClick={() => setAudioSrc(undefined)}
                                fontSize={'small'}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.alignEnd}>
                {!globalLoading && (
                    <>
                        <label
                            onClick={onCancelLocal}
                            className={
                                globalStyles
                                    ? styles.cancelBtn
                                    : styles.disabledCancelBtn
                            }
                        >
                            Cancelar
                        </label>
                        <label
                            onClick={onSaveLocal}
                            className={
                                globalStyles
                                    ? styles.saveBtn
                                    : styles.disabledSaveBtn
                            }
                        >
                            Salvar
                        </label>
                    </>
                )}
            </div>
        </div>
    )
}

export default TagForm
