import React from 'react'
import styles from './styles'

interface Props {
    children: any
    triggerInput: (e: any) => void
    inputRef: React.RefObject<HTMLInputElement>
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ImageUploader = (props: Props) => {
    const { children, triggerInput, inputRef, onChange } = props
    let hiddenInputStyle = {}
    // If user passes in children, display children and hide input.
    if (children) {
        hiddenInputStyle = {
            position: 'absolute',
            top: '-9999px',
        }
    }

    return (
        <div style={styles.cursorPointer} onClick={triggerInput}>
            <input
                style={hiddenInputStyle}
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={onChange}
            />
            <div style={styles.uploader}>{children()}</div>
        </div>
    )
}

export default ImageUploader
