import firebase from 'firebase'
import constants from '../values/constants'

const firebaseConfig = constants.FIREBASE.PROD_CONF

const initFirebase = () => {
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig)
    }
}

export { initFirebase }
