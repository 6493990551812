export default {
    USER_TYPES: {
        admin: 'admin',
        analyst: 'analyst',
        client: 'client',
    },
    FIREBASE: {
        DEV_CONF: {
            apiKey: 'AIzaSyA5C5Ub7tM4gy3saXj_18Z-RJEZekXSRKM',
            authDomain: 'amanda-dreher-devlopment.firebaseapp.com',
            databaseURL: 'https://amanda-dreher-devlopment.firebaseio.com',
            projectId: 'amanda-dreher-devlopment',
            storageBucket: 'amanda-dreher-devlopment.appspot.com',
            messagingSenderId: '1059378260781',
            appId: '1:1059378260781:web:523e53e178b0364d9401a4',
            measurementId: 'G-1LQ193FZ2Y',
        },
        PROD_CONF: {
            apiKey: 'AIzaSyAp5Y93SkZYokX6UZHn7f4AcWMyfP-W67g',
            authDomain: 'amanda-dreher.firebaseapp.com',
            databaseURL: 'https://amanda-dreher.firebaseio.com',
            projectId: 'amanda-dreher',
            storageBucket: 'amanda-dreher.appspot.com',
            messagingSenderId: '180292987443',
            appId: '1:180292987443:web:97a3d7e0a4321e60149146',
            measurementId: 'G-FXGQ4EWZZW',
        },
    },
    // SENTRY: {
    //     DSN:
    //         'https://40e178f7af284271bfb730d6ff2daba7@o378751.ingest.sentry.io/5312961',
    // },
    // HOTJART: {
    //     HJID: 1913188,
    // },
}
