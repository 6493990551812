import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../values/colors'

const overrides: Overrides = {
    MuiTable: {
        root: {
            minWidth: 650,
            paddingRight: 34,
            paddingLeft: 32,
        },
    },
    MuiTableCell: {
        root: {
            letterSpacing: -0.24,
        },
        head: {
            fontFamily: 'Poppins',
            fontSize: '1rem',
            color: colors.blacks.three,
        },
        body: {
            fontFamily: 'Poppins',
            fontWeight: 'normal',
            fontSize: '0.9rem',
            color: colors.blacks.one,
        },
    },
    MuiTablePagination: {
        root: {
            '& > *': {
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '0.9rem',
                letterSpacing: -0.24,
                color: '#AAAAAA',
            },
        },
    },
    MuiSelect: {
        selectMenu: {
            backgroundColor: colors.whites.one,
            padding: '7px 24px 7px 10px',
            borderRadius: 2,
            width: 233,
            height: 26,
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Poppins',
            fontSize: 14,
            letterSpacing: -0.24,
            color: colors.blacks.three,
        },
    },
    MuiOutlinedInput: {
        input: {
            color: colors.blacks.four,
        },
    },
}

export default overrides
