import colors from './colors'

export default {
    row: {
        display: 'flex',
        'flex-direction': 'row',
    },
    column: {
        display: 'flex',
        'flex-direction': 'column',
    },
    rowMarginBottom15: {
        display: 'flex',
        'flex-direction': 'row',
        width: '100%',
        alignItems: 'center',
        marginBottom: 15,
    },
    tableItemArea: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    searchInput: {
        width: 233,
        backgroundColor: colors.whites.one,
        borderRadius: 2,
        height: 40,
        '& > *': {
            fontFamily: 'Poppins',
            fontSize: 14,
            letterSpacing: -0.24,
            color: colors.blacks.three,
            height: 40,
        },
    },
}
