import React from 'react'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import PeopleIcon from '@material-ui/icons/People'
import Chip from '@material-ui/core/Chip'

import useStyles from './muiStyles'
import { User } from '../../../../types/User'

type Props = {
    user: User
}

const UserData: React.FC<Props> = ({ user }) => {
    const muiStyles = useStyles()

    return (
        <Paper className={muiStyles.paper}>
            <h1 className={muiStyles.title}>
                <PeopleIcon className={muiStyles.titleIcon} /> Dados do Usuário
            </h1>
            <div className={muiStyles.section}>
                <legend className={muiStyles.legend}>Nome</legend>
                <TextField
                    className={muiStyles.textField}
                    disabled
                    value={user.displayName || '-'}
                    variant="outlined"
                />
            </div>
            <div className={muiStyles.section}>
                <legend className={muiStyles.legend}>E-mail</legend>
                <TextField
                    className={muiStyles.textField}
                    disabled
                    value={user.email}
                    variant="outlined"
                />
            </div>
            <div className={muiStyles.section}>
                <legend className={muiStyles.legend}>Interesses</legend>
                <div className={muiStyles.chipContainer}>
                    {user.tags.map((topic) => (
                        <Chip
                            key={topic}
                            label={topic}
                            color={'secondary'}
                            className={muiStyles.chip}
                        />
                    ))}
                </div>
            </div>
        </Paper>
    )
}

export default UserData
