/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
    Paper,
    Button,
    createMuiTheme,
    MuiThemeProvider,
    OutlinedInput,
    CircularProgress,
} from '@material-ui/core'
import firebase from 'firebase'
import colors from '../../values/colors'
import { images } from '../../assets'
import styles from './styles.module.css'
import ValidatorUtils from '../../utils/ValidatorUtils'
import firebaseErrors from '../../values/firebaseErrors'
import { routes } from '../index'
import ApplicationContext from '../../context'
import { BasePage } from '../../container'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        PaperRoot: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(4),
            width: theme.spacing(40),
            height: theme.spacing(41),
        },
        textInputRoot: {
            '& > *': {
                fontFamily: 'Poppins',
                fontSize: 14,
            },
            marginBottom: theme.spacing(3),
        },
        button: {
            backgroundColor: colors.backgroundBlue,
            marginBottom: theme.spacing(3),
            '& > *': {
                fontFamily: 'Poppins',
                fontSize: 14,
                fontWeight: 600,
                letterSpacing: 1.25,
                textTransform: 'uppercase',
                color: colors.white,
                height: 36,
            },
            '&:hover': {
                backgroundColor: colors.backgroundBlue,
                opacity: 0.8,
            },
        },
        circularProgress: {
            color: colors.white,
        },
    })
)

const ButtonTheme = createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
})

const RecoveryPasswordPage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [email, setEmail] = React.useState<string>('')
    const [resetSended, setResetSended] = React.useState<boolean>(false)
    const { showMessage } = useContext(ApplicationContext)

    const onChangEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const goToLogin = () => {
        history.push(routes.loginPage.path)
    }

    const submit = () => {
        if (!email || email.length === 0) {
            showMessage('Digite seu e-mail')
            return
        }

        if (!ValidatorUtils.isValidEmail(email)) {
            showMessage('Digite seu e-mail')
            return
        }

        setLoading(true)
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then((userInfo) => {
                setResetSended(true)
            })
            .catch((error) => {
                showMessage(firebaseErrors[error.code])
            })
            .finally(() => setLoading(false))
    }

    const _renderPaperContent = React.useMemo(() => {
        if (resetSended) {
            return (
                <div className={styles.centralize}>
                    <label className={styles.infoLabel}>Senha enviada!</label>
                    <label
                        onClick={goToLogin}
                        className={`${styles.forgetPasswordLabel} ${styles.blueWord}`}
                    >
                        Ir para o login
                    </label>
                </div>
            )
        }

        return (
            <>
                <label className={styles.ctaLabel}>Recupere sua senha</label>
                <label className={styles.infoLabel}>
                    Digite seu e-mail de cadastro para receber as instruções de
                    redefinição de senha
                </label>
                <OutlinedInput
                    id="email"
                    type="email"
                    placeholder={'E-mail'}
                    value={email}
                    classes={{ root: classes.textInputRoot }}
                    onChange={onChangEmail}
                />
                <MuiThemeProvider theme={ButtonTheme}>
                    <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        onClick={submit}
                    >
                        {!loading ? (
                            'ENVIAR'
                        ) : (
                            <CircularProgress
                                size={20}
                                classes={{ root: classes.circularProgress }}
                            />
                        )}
                    </Button>
                </MuiThemeProvider>
                <label
                    onClick={goToLogin}
                    className={styles.forgetPasswordLabel}
                >
                    Lembrou da <span className={styles.blueWord}>senha?</span>
                </label>
            </>
        )
    }, [
        classes.button,
        classes.circularProgress,
        classes.textInputRoot,
        email,
        goToLogin,
        loading,
        resetSended,
        submit,
    ])

    return (
        <BasePage hideAuthContent>
            <div className={styles.container}>
                <img
                    src={images.logo}
                    alt={'logo do sistema'}
                    className={styles.logo}
                />
                <Paper classes={{ root: classes.PaperRoot }}>
                    {_renderPaperContent}
                </Paper>
            </div>
        </BasePage>
    )
}

export default RecoveryPasswordPage
