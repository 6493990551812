import React, { useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'
import firebase from 'firebase'

import ApplicationContext from '../context'
import { User } from '../types/User'
import { routes } from '../views'
import PrivateRoute from './PrivateRoute'

const AppRouter = () => {
    const authentication = localStorage.getItem('authentication')
    const savedUser: any = authentication
        ? JSON.parse(authentication)
        : undefined
    const [authUser, setAuthUser] = useState<User | undefined>(savedUser)
    const [globalLoading, setGlobalLoading] = useState<boolean>(false)
    const [snackVisible, setSnackVisible] = useState<boolean>(false)
    const [snackMessage, setSnackMessage] = useState('')

    const showSnackMessage = (msg: string) => {
        setSnackMessage(msg)
        setSnackVisible(true)
    }

    const closeSnackMessage = () => {
        setSnackMessage('')
        setSnackVisible(false)
    }

    const doLogout = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                localStorage.removeItem('authentication')
                setAuthUser(undefined)
            })
            .catch((error) => console.log(error.message))
    }

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (!!user) {
                firebase
                    .firestore()
                    .collection('user_profile')
                    .where('email', '==', user.email)
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            if (doc.data().isAdmin) {
                                // @ts-ignore
                                setAuthUser(doc.data())
                                localStorage.setItem(
                                    'authentication',
                                    JSON.stringify(doc.data())
                                )
                            } else {
                                doLogout()
                                showSnackMessage(
                                    'Somente usuários administradores podem acessar o painel'
                                )
                            }
                        })
                    })
                    .catch((error) => showSnackMessage(error.message))
            } else {
                localStorage.removeItem('authentication')
                setAuthUser(undefined)
            }
        })
    }, [])

    const renderRoot = React.useCallback(() => {
        const _routes = Object.entries(routes).filter(
            ([_, route]) => !route.requiresAuth
        )

        return _routes.map(([_, route]) => {
            return (
                <Route
                    key={route.path}
                    exact
                    path={route.path}
                    component={route.Component}
                />
            )
        })
    }, [])

    const renderPrivateRoutes = React.useCallback(() => {
        const _routes = Object.entries(routes).filter(
            ([_, route]) => route.requiresAuth
        )

        return _routes.map(([_, route]) => {
            return (
                <PrivateRoute
                    key={route.path}
                    exact
                    path={route.path}
                    component={route.Component}
                />
            )
        })
    }, [])

    const loadAuthUser = (user: User) => {
        setAuthUser(user)
        localStorage.setItem('authentication', JSON.stringify(user))
    }

    const _renderRoutes = React.useMemo(() => {
        if (!!authUser) {
            return renderPrivateRoutes()
        }
        return renderRoot()
    }, [authUser, renderPrivateRoutes, renderRoot])

    const contextValues = {
        authUser,
        globalLoading,
        setGlobalLoading,
        setAuthUser: loadAuthUser,
        snackVisible,
        snackMessage,
        closeMessage: closeSnackMessage,
        showMessage: showSnackMessage,
        doLogout,
    }

    return (
        <ApplicationContext.Provider value={contextValues}>
            <Router>
                <Switch>
                    {_renderRoutes}
                    <Route
                        path="*"
                        exact
                        component={() => <Redirect to={'/'} />}
                    />
                </Switch>
            </Router>
        </ApplicationContext.Provider>
    )
}

export default AppRouter
