import React from 'react'
import { TableCell, TableRow, IconButton, Avatar } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { User } from '../../../../types/User'
import styles from './styles.module.css'

type Props = User & { onClick: () => void }

const UserItem: React.FC<Props> = ({
    id,
    displayName,
    email,
    premiumAppStore,
    premiumHotmart,
    premiumPlayStore,
    tags,
    photoUrl,
    onClick,
}) => {
    const isPremium = premiumAppStore || premiumHotmart || premiumPlayStore

    return (
        <TableRow key={id}>
            <TableCell component="th" scope="row">
                <div className={styles.FullName}>
                    <Avatar src={photoUrl} />
                    {displayName || '-'}
                </div>
            </TableCell>
            <TableCell>{email}</TableCell>
            <TableCell
                className={isPremium ? styles.IsPremium : styles.NotIsPremium}
            >
                {isPremium ? 'Sim' : 'Não'}
            </TableCell>
            <TableCell>{!!tags && tags.join(', ')}</TableCell>
            <TableCell>
                <IconButton onClick={onClick}>
                    <VisibilityIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default UserItem
