import { makeStyles } from '@material-ui/core'
import colors from '../../../../values/colors'

const muiStyles = makeStyles({
    paper: {
        padding: '2rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 32,
        marginRight: 32,
    },
    avatar: {
        width: 96,
        height: 96,
        marginRight: 33,
        '& .MuiAvatar-img': {
            objectFit: 'contain',
        },
    },
    infoContainer: {},

    title: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.2rem',
        lineHeight: '16px',
        letterSpacing: '0.996px',
        textTransform: 'uppercase',
        color: colors.blacks.two,
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: colors.purples.one,
        padding: '0.5rem',
        fontSize: '0.8rem',
        color: colors.white,
        borderRadius: 2,
        marginBottom: 10,
    },
    dateIcon: {
        width: '1rem',
        height: '1rem',
        marginRight: '0.3rem',
        color: colors.white,
    },
    premium: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
        letterSpacing: '0.996px',
        textTransform: 'uppercase',
        color: colors.yellows.one,
    },
    crow: {
        width: 24,
        height: 24,
        marginLeft: '0.5rem',
    },
})

export default muiStyles
