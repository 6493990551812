import React from 'react'
import { TableRow, TableCell, withStyles, Checkbox } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled'
import EditIcon from '@material-ui/icons/Edit'
import { Music } from '../../../types/Musics'
import colors from '../../../values/colors'
import useStyles from '../material.styles'
import styles from './styles.module.css'

const GreenCheckbox = withStyles({
    root: {
        color: colors.blacks.one,
        '&$checked': {
            color: colors.greens.one,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

type Props = {
    row: Music
    index: number
    handleEdit: (row: Music) => void
    handleActiveOrDisable: (checked: boolean, docId: string) => void
}
const MusicItem: React.FC<Props> = ({
    row,
    index,
    handleEdit,
    handleActiveOrDisable,
}) => {
    const classes = useStyles()
    const [play, setPlay] = React.useState<boolean>(false)

    const playOrPause = () => {
        setPlay(!play)
    }

    return (
        <TableRow key={`${index}-${row.title}`} className={classes.tableRow}>
            <TableCell className={classes.tableText}>{row.title}</TableCell>
            <TableCell className={classes.tableText}>
                <GreenCheckbox
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => handleActiveOrDisable(checked, row.docId!)}
                    checked={!!row.active}
                />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
                <EditIcon
                    onClick={() => handleEdit(row)}
                    className={classes.editBtn}
                />
            </TableCell>
            <TableCell>
                {play ? (
                    <PauseCircleFilled
                        onClick={() => playOrPause()}
                        className={classes.playBtn}
                    />
                ) : (
                    <PlayCircleFilled
                        onClick={() => playOrPause()}
                        className={classes.playBtn}
                    />
                )}
                {play && (
                    <audio className={styles.player} autoPlay controls>
                        <source src={row.url} type="audio/mpeg" />
                    </audio>
                )}
            </TableCell>
        </TableRow>
    )
}

export default MusicItem
