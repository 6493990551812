const styles: {
    [key: string]: React.CSSProperties
} = {
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    uploader: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box'
    }
}

export default styles
