import { maskJs } from 'mask-js'

class MaskUtils {
    static toCpf = (cpf: string): string => {
        const val = maskJs('999.999.999-99', cpf)
        return val
    }

    static toPhone = (phone: string): string => {
        if (phone) {
            const cellphoneMask = '(99) 99999-9999'
            const phoneMask = '(99) 9999-9999'

            const mask = phone.length === 11 ? cellphoneMask : phoneMask
            const val = maskJs(mask, phone)
            return val
        }
        return ''
    }

    static toDate = (date: string): string => {
        let v = date.replace(/\D/g, '').slice(0, 10)
        if (v.length >= 5) {
            return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
        } else if (v.length >= 3) {
            return `${v.slice(0, 2)}/${v.slice(2)}`
        }
        return v
    }

    static toHour = (date: string): string => {
        if (date.length > 2) {
            let v = date.replace(/\D/g, '').slice(0, 5)
            return `${v.slice(0, 2)}:${v.slice(2)}`
        }

        return date
    }

    static toOnlyNumbers = (value: string) => {
        return value
            .replace(/\s/g, '')
            .replace(/-/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/\D/g, '')
            .trim()
    }
}

export default MaskUtils
