import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import styles from './styles.module.css'
import { icons } from '../../../assets'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(3),
            width: theme.spacing(43),
            height: theme.spacing(14),
            padding: theme.spacing(3),
        },
    })
)
type Props = {
    title: string
    count: number
    icon: 'circle' | 'person' | 'group' | 'crown'
}

const DashboardCard: React.FC<Props> = ({ title, count, icon }) => {
    const classes = useStyles()
    return (
        <Paper classes={{ root: classes.root }}>
            <div className={styles.firstRow}>
                <label className={styles.title}>{title}</label>
                <label className={styles.count}>{count}</label>
            </div>
            <div className={styles.iconCircleWrapper}>
                <img src={icons[icon]} alt={''} />
            </div>
        </Paper>
    )
}

export default DashboardCard
