import React, { useState, useEffect, useContext, useCallback } from 'react'
import firebase from 'firebase'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Fab,
    TablePagination,
    LinearProgress,
    InputAdornment,
    TextField,
    Select,
} from '@material-ui/core/'

import { Meditation } from '../../types/Meditations'
import { BasePage } from '../../container'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../context'
import MeditationItem from './layouts/MeditationItem'
import MeditationForm from './layouts/Form'
import { routes } from '..'
import { Tag } from '../../types/Tags'

const COLLECTION_NAME = 'meditation'
const ORDER_BY = 'createdAt'
const SORTED_BY = 'desc'

const MeditationsPage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const [meditations, setMeditations] = useState<Meditation[]>([])
    const [tags, setTags] = useState<Tag[]>([])
    const [tagMap, setTagMap] = useState<{
        [key: string]: string
    }>({})
    const [selectedTag, setSelectedTag] = useState<string>('Tags')
    // const [premiumFilter, setPremiumFilter] = useState<string>(
    //     'Meditação grátis'
    // )
    const [searchText, setSearchText] = useState<string>('')
    const [rowsPerPage, setRowsPerPage] = useState<number>(25)
    const [page, setPage] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [isNew, setIsNew] = useState<boolean>(false)
    const [isInEdit, setIsInEdit] = useState<boolean>(false)

    const { showMessage, globalLoading } = useContext(ApplicationContext)

    useEffect(() => {
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .get()
            .then((snapshot) => setTotal(snapshot.size))
            .catch(() => showMessage('Falha ao pegar o total de items'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew, isInEdit])

    const updateMeditations = (snapshot) => {
        const meditationsBuilder: any[] = []
        snapshot.forEach((doc) => {
            meditationsBuilder.push({
                docId: doc.id,
                ...doc.data(),
            })
        })
        setMeditations(meditationsBuilder)
    }

    const requestWithoutFilters = useCallback(() => {
        setLoading(true)
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .orderBy(ORDER_BY, SORTED_BY)
            .limit(rowsPerPage)
            .onSnapshot((snapshot) => {
                setLoading(false)
                updateMeditations(snapshot)
            })
    }, [rowsPerPage])

    useEffect(() => {
        setLoading(true)
        requestWithoutFilters()
        firebase
            .firestore()
            .collection('tags')
            .onSnapshot((snapshot) => {
                const tagsBuilder: any[] = []
                const tagMapBuilder: { [key: string]: string } = {}
                snapshot.forEach((doc) => {
                    if (doc.data().active) {
                        tagsBuilder.push(doc.data())
                        tagMapBuilder[doc.data().tag] = `${doc.data().tag} (${
                            doc.data().title
                        })`
                    }
                })
                setTagMap(tagMapBuilder)
                setTags(tagsBuilder.sort())
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const existTextToSearch = !!searchText && searchText !== ''
        if (existTextToSearch) {
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .get()
                .then((snapshot) => {
                    const meditationsBuilder: any[] = []
                    snapshot.forEach((doc) => {
                        if (
                            Object.values(doc.data())
                                .join(',')
                                .toUpperCase()
                                .match(searchText.toUpperCase())
                        )
                            meditationsBuilder.push({
                                docId: doc.id,
                                ...doc.data(),
                            })
                    })
                    setMeditations(meditationsBuilder)
                })
                .catch((error) => {
                    showMessage(error.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [searchText, showMessage])

    useEffect(() => {
        const existTagToSearch =
            !!selectedTag && selectedTag !== 'Tags' && selectedTag !== ''

        if (existTagToSearch) {
            setLoading(true)
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .get()
                .then((snapshot) => {
                    const meditationsBuilder: any[] = []
                    snapshot.forEach((doc) => {
                        if (doc.data().tags.includes(selectedTag))
                            meditationsBuilder.push({
                                docId: doc.id,
                                ...doc.data(),
                            })
                    })
                    setMeditations(meditationsBuilder)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [selectedTag])

    // useEffect(() => {
    //     const existPremiumFilter =
    //         !!premiumFilter && premiumFilter !== 'Meditação grátis'

    //     if (existPremiumFilter) {
    //         setLoading(true)
    //         firebase
    //             .firestore()
    //             .collection(COLLECTION_NAME)
    //             .get()
    //             .then((snapshot) => {
    //                 const premiumValue = (premiumFilter !== 'Sim') as boolean
    //                 const meditationsBuilder: any[] = snapshot.docs
    //                     .filter((doc) => {
    //                         if (premiumValue) {
    //                             return !!doc.data().premium
    //                         }

    //                         return !doc.data().premium
    //                     })
    //                     .map((doc) => ({
    //                         docId: doc.id,
    //                         ...doc.data(),
    //                     }))
    //                 setMeditations(meditationsBuilder)
    //             })
    //             .finally(() => {
    //                 setLoading(false)
    //             })
    //     }
    // }, [premiumFilter])

    const [previousCreatedAt, setPreviousCreatedAt] = useState<Date>()

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setPage(newPage)

        let creator = firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .orderBy(ORDER_BY, SORTED_BY)

        if (page < newPage) {
            creator = creator
                .startAfter(meditations[meditations.length - 1].createdAt)
                .limit(rowsPerPage)

            setPreviousCreatedAt(meditations[meditations.length - 1].createdAt)
        } else {
            const endBeforeParam =
                meditations.length !== 0
                    ? meditations[0].createdAt
                    : previousCreatedAt

            creator = creator.endBefore(endBeforeParam).limitToLast(rowsPerPage)
        }

        creator
            .get()
            .then(updateMeditations)
            .finally(() => setLoading(false))
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setLoading(true)
        setRowsPerPage(parseInt(event.target.value))
        setMeditations([])
        setPage(0)
        // setPremiumFilter('Meditação grátis')
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .orderBy(ORDER_BY, SORTED_BY)
            .limit(parseInt(event.target.value))
            .get()
            .then((snapshot) => {
                setLoading(false)
                updateMeditations(snapshot)
            })
            .catch((error) => showMessage(error.message))
    }

    const handleActiveOrDisable = (checked: boolean, docId: string) => {
        setLoading(true)
        const backUpMeditations = [...meditations]
        const cloneMeditations = [...meditations]
        const index = cloneMeditations.findIndex((med) => med.docId === docId)
        cloneMeditations.splice(index, 1, {
            ...cloneMeditations[index],
            active: checked,
        })
        setMeditations(cloneMeditations)
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .doc(docId)
            .set({ active: checked }, { merge: true })
            .then(() =>
                showMessage(`A meditação foi ${checked ? '' : 'in'}ativada`)
            )
            .catch((error) => {
                setMeditations(backUpMeditations)
                showMessage(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleEdit = (meditation: Meditation) => {
        history.push(routes.meditationsEditPage.path, { meditation })
    }

    const handleSelectTag = (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode
    ) => {
        const tagValue = event.target.value as string
        setSelectedTag(tagValue)
        if (tagValue === 'Tags') {
            requestWithoutFilters()
        }
    }

    // const handleSelectPremium = (
    //     event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    //     child: React.ReactNode
    // ) => {
    //     const premiumSelectValue = event.target.value as string
    //     setPremiumFilter(premiumSelectValue)
    //     if (premiumSelectValue === 'Meditação grátis') {
    //         requestWithoutFilters()
    //     }
    // }

    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
        if (event.target.value === '') {
            requestWithoutFilters()
        }
    }

    const handleNewMeditation = () => {
        setIsNew(true)
    }

    const onCancelMeditation = () => {
        setIsNew(false)
        setIsInEdit(false)
    }

    const sortTags = (a, b) => {
        const aText = `${a.tag} (${a.title})`
        const bText = `${b.tag} (${b.title})`
        if (aText > bText) return 1
        if (aText < bText) return -1
        return 0
    }

    return (
        <BasePage>
            {(loading || globalLoading) && <LinearProgress />}
            <div className={styles.container}>
                <Fab
                    onClick={() => handleNewMeditation()}
                    classes={{ root: classes.actionButton }}
                    aria-label="add"
                >
                    <AddIcon classes={{ root: classes.addIcon }} />
                </Fab>
                {(isNew || isInEdit) && (
                    <MeditationForm
                        tags={tags.map((tg) => tg.tag)}
                        onCancel={onCancelMeditation}
                        showList={() => onCancelMeditation()}
                    />
                )}
                {!isInEdit && (
                    <div className={styles.tableWrapper}>
                        <div className={styles.searchWrapper}>
                            <TextField
                                id="outlined-start-adornment"
                                value={searchText}
                                onChange={handleSearchText}
                                className={classes.searchField}
                                placeholder={'Localizar'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant={'outlined'}
                            />
                            {/* <Select
                                value={premiumFilter}
                                onChange={handleSelectPremium}
                                variant={'outlined'}
                                style={{ marginRight: 16 }}
                                renderValue={(value) => {
                                    const selected = value as string
                                    if (selected === 'Meditação grátis') {
                                        return (
                                            <div className={styles.selectInput}>
                                                {selected}
                                            </div>
                                        )
                                    }
                                    return (
                                        <div className={styles.selectInput}>
                                            {`Meditação grátis: ${selected}`}
                                        </div>
                                    )
                                }}
                            >
                                <option
                                    className={styles.selectOptionsPlaceholder}
                                    value={'Meditação grátis'}
                                >
                                    Meditação grátis
                                </option>
                                <option
                                    className={styles.selectOptions}
                                    value={'Não'}
                                >
                                    Não
                                </option>
                                <option
                                    className={styles.selectOptions}
                                    value={'Sim'}
                                >
                                    Sim
                                </option>
                            </Select> */}
                            <Select
                                value={selectedTag}
                                onChange={handleSelectTag}
                                label={'Tags'}
                                displayEmpty
                                renderValue={(value) => {
                                    const selected = value as string
                                    if (selected === 'Tags') {
                                        return (
                                            <div className={styles.selectInput}>
                                                {selected}
                                            </div>
                                        )
                                    }
                                    return (
                                        <div className={styles.selectInput}>
                                            {tagMap[selected]}
                                        </div>
                                    )
                                }}
                                variant={'outlined'}
                                inputProps={{
                                    'aria-label': 'Without label',
                                }}
                            >
                                <option
                                    className={styles.selectOptionsPlaceholder}
                                    aria-label="Tags"
                                    value="Tags"
                                >
                                    Tags
                                </option>
                                {tags
                                    .sort(sortTags)
                                    .map(({ tag, title }, index) => (
                                        <option
                                            className={styles.selectOptions}
                                            value={tag}
                                        >
                                            {`${tag} (${title})`}
                                        </option>
                                    ))}
                            </Select>
                        </div>
                        <TableContainer component={Paper}>
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Imagem
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Título
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Descrição
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Tempo
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Tag
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Música de fundo
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Grátis
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Ativado
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {meditations.map(
                                        (
                                            meditation: Meditation,
                                            index: number
                                        ) => (
                                            <MeditationItem
                                                row={meditation}
                                                index={index}
                                                handleEdit={() =>
                                                    handleEdit(meditation)
                                                }
                                                handleActiveOrDisable={
                                                    handleActiveOrDisable
                                                }
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={total}
                                className={classes.pagination}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage={'Itens por página:'}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </div>
                )}
            </div>
        </BasePage>
    )
}

export default MeditationsPage
