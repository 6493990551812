import React, { useContext } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { useHistory, useLocation } from 'react-router-dom'
import { Fab, LinearProgress } from '@material-ui/core/'

import { Notification } from '../../types/Notifications'
import { BasePage } from '../../container'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../context'
import NotificationForm from './layouts/Form'

const NotificationsEditPage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const {
        state: { notification },
    } = useLocation<{ notification: Notification }>()

    const { globalLoading } = useContext(ApplicationContext)

    const onCancelNotification = () => {
        history.goBack()
    }

    return (
        <BasePage>
            {globalLoading && <LinearProgress />}
            <div className={styles.container}>
                <Fab
                    onClick={() => {}}
                    classes={{ root: classes.actionButton }}
                    aria-label="add"
                >
                    <AddIcon classes={{ root: classes.addIcon }} />
                </Fab>
                <NotificationForm
                    notification={notification}
                    onCancel={onCancelNotification}
                    showList={() => onCancelNotification()}
                />
            </div>
        </BasePage>
    )
}

export default NotificationsEditPage
