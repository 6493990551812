import React, { useState, useEffect } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { useHistory, useLocation } from 'react-router-dom'
import { Fab } from '@material-ui/core/'
import firebase from 'firebase'

import { Meditation } from '../../types/Meditations'
import { BasePage } from '../../container'
import useStyles from './material.styles'
import styles from './styles.module.css'
import MeditationForm from './layouts/Form'

const MeditationsEditPage: React.FC = () => {
    const {
        state: { meditation },
    } = useLocation<{ meditation: Meditation }>()
    const history = useHistory()
    const classes = useStyles()
    const [tags, setTags] = useState<string[]>([])
    const [meditationToEdit] = useState<Meditation>(meditation)

    useEffect(() => {
        if (tags.length === 0) {
            firebase
                .firestore()
                .collection('tags')
                .onSnapshot((snapshot) => {
                    const tagsBuilder: any[] = []
                    snapshot.forEach((doc) => {
                        tagsBuilder.push(doc.data().tag)
                    })
                    setTags(tagsBuilder)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCancelMeditation = () => {
        history.goBack()
    }

    return (
        <BasePage>
            <div className={styles.container}>
                <Fab
                    onClick={() => {}}
                    classes={{ root: classes.actionButton }}
                    aria-label="add"
                >
                    <AddIcon classes={{ root: classes.addIcon }} />
                </Fab>
                <MeditationForm
                    tags={tags}
                    meditation={meditationToEdit}
                    onCancel={onCancelMeditation}
                    showList={() => onCancelMeditation()}
                />
            </div>
        </BasePage>
    )
}

export default MeditationsEditPage
