import React, { useState, useEffect, useContext, useCallback } from 'react'
import firebase from 'firebase'
import {
    List,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableContainer,
    LinearProgress,
    Paper,
    TableBody,
    Button,
} from '@material-ui/core'
import RootRef from '@material-ui/core/RootRef'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'

import { Tag } from '../../types/Tags'
import { BasePage } from '../../container'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../context'
import TagItem from './layouts/TagItem'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { routes } from '..'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: 'rgb(235,235,235)',
    }),
})

const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
})

const OrderTagsPage: React.FC = () => {
    const history = useHistory()
    const classes = useStyles()
    const [tags, setTags] = useState<Tag[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const [isMajor, setIsMajor] = useState<boolean>(false)

    const { showMessage, globalLoading } = useContext(ApplicationContext)

    const updateTags = (snapshot) => {
        const tagsBuilder: any[] = []
        snapshot.forEach((doc) => {
            tagsBuilder.push({
                docId: doc.id,
                ...doc.data(),
            })
        })
        setTags(tagsBuilder)
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        firebase
            .firestore()
            .collection('tags')
            .where('major', '==', isMajor)
            .orderBy('index', 'asc')
            .onSnapshot((snapshot) => {
                if (snapshot.size === 0) {
                    setLoading(false)
                } else {
                    updateTags(snapshot)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMajor])

    const onDragEnd = useCallback(
        (result) => {
            // dropped outside the list
            if (!result.destination) {
                return
            }

            const items = reorder(
                tags,
                result.source.index,
                result.destination.index
            )

            setTags(items as Tag[])
        },
        [tags]
    )

    const handleTagType = (
        event: React.MouseEvent<HTMLElement>,
        newType: boolean
    ) => {
        setIsMajor(newType)
    }

    const onSubmitOrder = async () => {
        setLoading(true)
        const tagRef = firebase.firestore().collection('tags')
        for (let tag of tags) {
            const index = tags.findIndex((tg) => tg.docId === tag.docId)
            await tagRef
                .doc(tag.docId)
                .set({ index: index + 1 }, { merge: true })
        }
        showMessage('Ordenação salva')
        setLoading(false)
        history.push(routes.tagsPage.path)
    }

    return (
        <BasePage>
            {(loading || globalLoading) && <LinearProgress />}
            <div className={styles.container}>
                <div className={styles.tableWrapper}>
                    <div className={'row space-between'}>
                        <ToggleButtonGroup
                            size="small"
                            value={isMajor}
                            exclusive
                            className={styles.toggle}
                            onChange={handleTagType}
                        >
                            <ToggleButton value={false}>
                                Ordenar tags não principais
                            </ToggleButton>
                            <ToggleButton value={true}>
                                Ordenar tags principais
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Button
                            size={'small'}
                            color={'inherit'}
                            onClick={() => history.goBack()}
                            classes={{ root: classes.buttonCancel }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            size={'small'}
                            onClick={onSubmitOrder}
                            classes={{ root: classes.button }}
                        >
                            Salvar ordenação
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align={'left'}
                                        className={classes.tableTitle}
                                    >
                                        Índice
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={classes.tableTitle}
                                    >
                                        Imagem
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={classes.tableTitle}
                                    >
                                        Nome da tag
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={classes.tableTitle}
                                    >
                                        Título
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={classes.tableTitle}
                                    >
                                        Subtítulo
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={classes.tableTitle}
                                    >
                                        Tag Principal
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody></TableBody>
                        </Table>
                    </TableContainer>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <RootRef rootRef={provided.innerRef}>
                                    <List
                                        style={getListStyle(
                                            snapshot.isDraggingOver
                                        )}
                                    >
                                        {tags.map((tag: Tag, index: number) => (
                                            <Draggable
                                                key={tag.docId}
                                                draggableId={tag.docId}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <TagItem
                                                        row={tag}
                                                        index={index}
                                                        ContainerComponent="li"
                                                        ContainerProps={{
                                                            ref:
                                                                provided.innerRef,
                                                        }}
                                                        provided={provided}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided
                                                                .draggableProps
                                                                .style
                                                        )}
                                                    />
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </List>
                                </RootRef>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </BasePage>
    )
}

export default OrderTagsPage
