import React from 'react'
import { Snackbar, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import { Option } from '../../types/View'
import { SideBar, Header } from './layouts'
import styles from './styles.module.css'
import { routes } from '../../views'
import colors from '../../values/colors'
import ApplicationContext from '../../context'

const useStyles = makeStyles({
    closeIcon: {
        color: colors.white,
        cursor: 'pointer',
    },
    snackBar: {
        alignItems: 'flex-end',
    },
})

type Props = {
    children: any
    hideAuthContent?: boolean
}

const BasePage: React.FC<Props> = ({ children, hideAuthContent }) => {
    const history = useHistory()
    const classes = useStyles()
    const options: Option[] = [
        {
            title: 'Dashboard',
            onClick: () => history.push(routes.dashboard.path),
            pathName: routes.dashboard.path,
        },
        {
            title: 'Usuários',
            onClick: () => history.push(routes.usersPage.path),
            pathName: routes.usersPage.path,
        },
        {
            title: 'Meditações',
            onClick: () => history.push(routes.meditations.path),
            pathName: routes.meditations.path,
        },
        {
            title: 'Respirações',
            onClick: () => history.push(routes.breathPage.path),
            pathName: routes.breathPage.path,
        },
        {
            title: 'Músicas',
            onClick: () => history.push(routes.musicPage.path),
            pathName: routes.musicPage.path,
        },
        {
            title: 'Notificações',
            onClick: () => history.push(routes.notificationsPage.path),
            pathName: routes.notificationsPage.path,
        },
        {
            title: 'Vídeos',
            onClick: () => history.push(routes.videoPage.path),
            pathName: routes.videoPage.path,
        },
        // {
        //     title: 'Administradores',
        //     onClick: () => {},
        //     pathName: '',
        // },
        {
            title: 'Dados externos',
            onClick: () => history.push(routes.externalDataPage.path),
            pathName: routes.externalDataPage.path,
        },
        {
            title: 'Tags',
            onClick: () => history.push(routes.tagsPage.path),
            pathName: routes.tagsPage.path,
        },
        {
            title: 'Frases do dia',
            onClick: () => history.push(routes.frontAgePage.path),
            pathName: routes.frontAgePage.path,
        },
    ]

    const { snackVisible, snackMessage, closeMessage } = React.useContext(
        ApplicationContext
    )

    return (
        <div className={styles.container}>
            {!hideAuthContent && <SideBar options={options} />}
            <div className={styles.content}>
                {!hideAuthContent && <Header />}
                {children}
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                key={`bottom-right`}
                open={snackVisible}
                classes={{ root: classes.snackBar }}
                autoHideDuration={3800}
                onClose={closeMessage}
                children={
                    <div
                        className={`${styles.simpleRow} ${styles.snackMessageContainer}`}
                    >
                        <label className={styles.snackMessageText}>
                            {snackMessage}
                        </label>
                        <CloseIcon
                            className={classes.closeIcon}
                            onClick={closeMessage}
                            fontSize={'small'}
                        />
                    </div>
                }
            />
        </div>
    )
}

export default BasePage
