import React from 'react'
import { BasePage } from '../../container'
import styles from './styles.module.css'
import { images } from '../../assets'
const URLs = {
    APP_STORE:
        'https://analytics.itunes.apple.com/#/apps?t=all&interval=r&datesel=d30',
    GOOGLE_PLAY:
        'https://play.google.com/apps/publish/?account=8221110389650536142#StatisticsPlace:p=com.amandadreher.meditacao&statms=ALL_ACTIVE_DEVICE_EVENTS_INTERVAL&statgs=DAILY&statd=OS_VERSION&statc=true&dvals=@OVERALL@&cask=false&statdr=20200714-20200812&statcdr=20200614-20200713&grdk=@OVERALL@&bpk=3:4ecb0e5d8bae3c21',
    ANALYTICS:
        'https://analytics.google.com/analytics/web/?hl=en_US#/p223432509/reports/home?params=_u..nav%3Dga1-experimental',
}
const ExternalDataPage: React.FC = () => {
    return (
        <BasePage>
            <div className={styles.container}>
                <a
                    href={URLs.APP_STORE}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className={styles.spacement}
                >
                    <img src={images.appStore} alt={'App Store'} />
                </a>
                <a
                    href={URLs.GOOGLE_PLAY}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className={styles.spacement}
                >
                    <img src={images.googlePlay} alt={'Google Play'} />
                </a>
                <a
                    href={URLs.ANALYTICS}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className={styles.spacement}
                >
                    <img
                        src={images.googleAnalytics}
                        alt={'Google Analytics'}
                    />
                </a>
            </div>
        </BasePage>
    )
}

export default ExternalDataPage
