import LoginPage from './login'
import RecoveryPasswordPage from './recoveryPassword'
import Dashboard from './dashboard'
import MeditationsPage from './meditations'
import MeditationsEditPage from './meditationsEdit'
import ExternalDataPage from './externalData'
import TagsPage from './tags'
import OrderTagsPage from './OrderTags'
import VideoPage from './video'
import UsersPage from './users'
import UserDetailPage from './userDetail'
import BreathPage from './breath'
import MusicPage from './music'
import NotificationsPage from './notifications'
import NotificationsEditPage from './notificationsEdit'
import FrontAgePage from './frontAgePage'

const routes = {
    loginPage: {
        name: 'Login',
        path: '/',
        Component: LoginPage,
        requiresAuth: false,
    },
    recoveryPasswordPage: {
        name: 'PasswordRecovery',
        path: '/forgot/password',
        Component: RecoveryPasswordPage,
        requiresAuth: false,
    },
    dashboard: {
        name: 'dashboard',
        path: '/',
        Component: Dashboard,
        requiresAuth: true,
    },
    meditations: {
        name: 'Meditations',
        path: '/meditations',
        Component: MeditationsPage,
        requiresAuth: true,
    },
    meditationsEditPage: {
        name: 'Meditations Edit',
        path: '/meditations/edit',
        Component: MeditationsEditPage,
        requiresAuth: true,
    },
    externalDataPage: {
        name: 'External Data',
        path: '/external/data',
        Component: ExternalDataPage,
        requiresAuth: true,
    },
    tagsPage: {
        name: 'Tags',
        path: '/tags',
        Component: TagsPage,
        requiresAuth: true,
    },
    orderTagsPage: {
        name: 'Tags Order',
        path: '/tags/order',
        Component: OrderTagsPage,
        requiresAuth: true,
    },
    videoPage: {
        name: 'Video',
        path: '/videos',
        Component: VideoPage,
        requiresAuth: true,
    },
    usersPage: {
        name: 'Tags',
        path: '/users',
        Component: UsersPage,
        requiresAuth: true,
    },
    userDetailPage: {
        name: 'User Detail',
        path: '/user/detail',
        Component: UserDetailPage,
        requiresAuth: true,
    },
    breathPage: {
        name: 'Breath',
        path: '/breath',
        Component: BreathPage,
        requiresAuth: true,
    },
    musicPage: {
        name: 'Music',
        path: '/music',
        Component: MusicPage,
        requiresAuth: true,
    },
    notificationsPage: {
        name: 'Notifications',
        path: '/notifications',
        Component: NotificationsPage,
        requiresAuth: true,
    },
    notificationsEditPage: {
        name: 'NotificationsEdit',
        path: '/notifications/edit',
        Component: NotificationsEditPage,
        requiresAuth: true,
    },
    frontAgePage: {
        name: 'FrontAgePage',
        path: '/front/age',
        Component: FrontAgePage,
        requiresAuth: true,
    },
}

const SCREEN_NAME_MAP = {
    [routes.dashboard.path]: 'Dashboard',
    [routes.usersPage.path]: 'Usuários',
    [routes.meditations.path]: 'Meditações',
    [routes.meditationsEditPage.path]: 'Meditações',
    [routes.breathPage.path]: 'Respirações',
    [routes.musicPage.path]: 'Músicas',
    [routes.notificationsPage.path]: 'Notificações',
    [routes.notificationsEditPage.path]: 'Notificações',
    [routes.videoPage.path]: 'Vídeos',
    // [routes.dashboard.path]: 'Administradores',
    [routes.externalDataPage.path]: 'Dados Externos',
    [routes.tagsPage.path]: 'Tags',
    [routes.userDetailPage.path]: 'Usuários',
    [routes.frontAgePage.path]: 'Frases do dia',
    [routes.orderTagsPage.path]: 'Ordenação das tags',
}

export { SCREEN_NAME_MAP, routes }
