import React, { useState, useContext } from 'react'

import { CircularProgress, Select } from '@material-ui/core'
import firebase from 'firebase'
import moment from 'moment'

import { Video, CreateVideoPack } from '../../../types/Videos'
import { TextField } from '@material-ui/core'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../../context'
import globalStyles from '../../../values/globalStyles'
import MaskUtils from '../../../utils/MaskUtils'
import DateUtils from '../../../utils/DateUtils'

type Props = {
    video?: Video
    onCancel: () => void
    showList: () => void
}

const VideoForm: React.FC<Props> = ({ video, onCancel, showList }) => {
    const classes = useStyles()
    const [title, setTitle] = useState<string>(video?.title || '')
    const [link, setLink] = useState<string>(video?.linkVideo || '')
    const [isLive, setIsLive] = useState<boolean>(video?.isLive || false)
    const [liveDate, setLiveDate] = useState<string>(
        video?.liveDate
            ? moment(video?.liveDate.seconds * 1000).format('DD/MM/YYYY')
            : ''
    )
    const [liveHour, setLiveHour] = useState<string>(video?.liveHour || '')

    const [postedAt, setPostedAt] = useState<string>(
        video?.postedAt
            ? moment(video?.postedAt.seconds * 1000).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY')
    )

    const { showMessage, setGlobalLoading, globalLoading } = useContext(
        ApplicationContext
    )

    const clearFields = () => {
        setTitle('')
        setLink('')
        setIsLive(false)
        setLiveDate('')
        setPostedAt('')
    }

    const onCancelLocal = () => {
        clearFields()
        onCancel()
    }

    const onSaveVideo = (videoPack: CreateVideoPack) => {
        setGlobalLoading(true)
        firebase
            .firestore()
            .collection('videos')
            .add(videoPack)
            .then(() => {
                clearFields()
                showList()
                showMessage('A video foi adicionado')
            })
            .catch((error) => showMessage(error.message))
            .finally(() => setGlobalLoading(false))
    }

    const onEditVideo = (videoPack: CreateVideoPack) => {
        setGlobalLoading(true)
        firebase
            .firestore()
            .collection('videos')
            .doc(videoPack?.docId)
            .set(videoPack, { merge: true })
            .then(() => {
                clearFields()
                showList()
                showMessage('O video foi alterado')
            })
            .catch((error) => showMessage(error.message))
            .finally(() => setGlobalLoading(false))
    }

    const onSaveLocal = () => {
        if (globalLoading) return

        if (!title || title.trim() === '') {
            showMessage('Digite o título do video')
            return
        }

        if (!link || link.trim() === '') {
            showMessage('Antes de salvar, indique o link do video')
            return
        }

        if (isLive) {
            if (!liveDate || liveDate.trim() === '') {
                showMessage('Digite a data live')
                return
            }

            if (!DateUtils.isValidDateString(liveDate)) {
                showMessage('Digite uma data válida para a live')
                return
            }

            if (!liveHour || liveHour.trim() === '') {
                showMessage('Digite a hora da live')
                return
            }

            if (!DateUtils.isValidHourString(liveHour)) {
                showMessage('Digite uma hora válida para a live')
                return
            }
        }

        if (!video) {
            const liveDateObj = isLive
                ? {
                      liveDate: firebase.firestore.Timestamp.fromDate(
                          moment(
                              `${liveDate} ${liveHour}`,
                              'DD/MM/YYYY hh:mm'
                          ).toDate()
                      ),
                      liveHour,
                  }
                : {}
            onSaveVideo({
                title,
                active: true,
                linkVideo: link,
                ...liveDateObj,
                isLive,
                postedAt: firebase.firestore.Timestamp.now(),
                createdAt: firebase.firestore.Timestamp.now().toDate(),
            })
        } else {
            const editLiveDateObj = isLive
                ? {
                      liveDate: firebase.firestore.Timestamp.fromDate(
                          moment(
                              `${liveDate} ${liveHour}`,
                              'DD/MM/YYYY hh:mm'
                          ).toDate()
                      ),
                      liveHour,
                  }
                : { liveDate: null, liveHour: '' }
            onEditVideo({
                ...video,
                active: true,
                title,
                linkVideo: link,
                isLive,
                ...editLiveDateObj,
            })
        }
    }

    const handleSelectIsLive = (
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode
    ) => {
        const value = event.target.value as string
        setIsLive(value === 'Sim')
        if (value === 'Não') {
            setLiveDate('')
            setLiveHour('')
        }
    }

    const handleLiveDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const unFormatted = event.target.value
            .replace(/\./g, '')
            .replace(/-/g, '')
            .trim()
        setLiveDate(MaskUtils.toDate(unFormatted))
    }

    const handlePostedAt = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!video) {
            const unFormatted = event.target.value
                .replace(/\./g, '')
                .replace(/-/g, '')
                .trim()
            setPostedAt(MaskUtils.toDate(unFormatted))
        }
    }

    const handleLiveHour = (event: React.ChangeEvent<HTMLInputElement>) => {
        const unFormatted = event.target.value
            .replace(/\./g, '')
            .replace(/-/g, '')
            .trim()
        setLiveHour(MaskUtils.toHour(unFormatted))
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.column} ${styles.paddingSecondColumn}`}>
                <div className={`${styles.row} ${styles.marginBottom8}`}>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Título</label>
                        <TextField
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            classes={{ root: classes.textField }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>

                    <div className={styles.column}>
                        <label className={styles.titleField}>Live</label>
                        <Select
                            value={isLive ? 'Sim' : 'Não'}
                            onChange={handleSelectIsLive}
                            className={classes.selectField}
                            variant={'outlined'}
                        >
                            <option
                                className={styles.selectOptions}
                                value={'Não'}
                            >
                                Não
                            </option>
                            <option
                                className={styles.selectOptions}
                                value={'Sim'}
                            >
                                Sim
                            </option>
                        </Select>
                    </div>
                    <div className={styles.column}>
                        <label className={styles.titleField}>
                            Data da Live
                        </label>
                        <TextField
                            value={liveDate}
                            onChange={handleLiveDate}
                            inputProps={{ maxLength: 10 }}
                            classes={{
                                root: !isLive
                                    ? classes.disabledTextField
                                    : classes.textDateHourField,
                            }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading || !isLive}
                        />
                    </div>
                    <div className={styles.column}>
                        <label className={styles.titleField}>
                            Hora da Live
                        </label>
                        <TextField
                            value={liveHour}
                            onChange={handleLiveHour}
                            inputProps={{ maxLength: 5 }}
                            classes={{
                                root: !isLive
                                    ? classes.disabledTextField
                                    : classes.textDateHourField,
                            }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading || !isLive}
                        />
                    </div>
                </div>
                <div className={`${styles.row} ${styles.marginBottom8}`}>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Link</label>
                        <TextField
                            value={link}
                            onChange={(event) => setLink(event.target.value)}
                            classes={{ root: classes.textLinkField }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading}
                        />
                    </div>
                    <div className={styles.column}>
                        <label className={styles.titleField}>Postado em:</label>
                        <TextField
                            value={postedAt}
                            onChange={handlePostedAt}
                            inputProps={{ maxLength: 10 }}
                            classes={{ root: classes.textPostedField }}
                            size="small"
                            variant="outlined"
                            disabled={globalLoading || !!video}
                        />
                    </div>
                </div>
                <div className={styles.alignEnd}>
                    {globalLoading ? (
                        <CircularProgress
                            size={20}
                            classes={{ root: classes.circularProgress }}
                        />
                    ) : (
                        <>
                            <label
                                onClick={onCancelLocal}
                                className={
                                    globalStyles
                                        ? styles.cancelBtn
                                        : styles.disabledCancelBtn
                                }
                            >
                                Cancelar
                            </label>
                            <label
                                onClick={onSaveLocal}
                                className={
                                    globalStyles
                                        ? styles.saveBtn
                                        : styles.disabledSaveBtn
                                }
                            >
                                Salvar
                            </label>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VideoForm
