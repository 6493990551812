import React from 'react'
import { TableRow, TableCell, withStyles, Checkbox } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import EditIcon from '@material-ui/icons/Edit'
import { Video } from '../../../types/Videos'
import colors from '../../../values/colors'
import useStyles from '../material.styles'

const GreenCheckbox = withStyles({
    root: {
        color: colors.blacks.one,
        '&$checked': {
            color: colors.greens.one,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

type Props = {
    row: Video
    index: number
    handleEdit: (meditation: Video) => void
    handleActiveOrDisable: (checked: boolean, docId: string) => void
}
const VideoItem: React.FC<Props> = ({
    row,
    index,
    handleEdit,
    handleActiveOrDisable,
}) => {
    const classes = useStyles()

    return (
        <TableRow key={`${index}-${row.title}`}>
            <TableCell className={classes.tableText}>{row.title}</TableCell>
            <TableCell className={classes.tableText}>{row.linkVideo}</TableCell>
            <TableCell
                className={
                    row.liveDate ? classes.tableTextYes : classes.tableTextNo
                }
            >
                {row.isLive ? 'Sim' : 'Não'}
            </TableCell>
            <TableCell className={classes.tableText}>
                {row.liveHour || ''}
            </TableCell>
            <TableCell className={classes.tableText}>
                <GreenCheckbox
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => handleActiveOrDisable(checked, row.docId!)}
                    checked={!!row.active}
                />
            </TableCell>
            <TableCell>
                <EditIcon
                    onClick={() => handleEdit(row)}
                    className={classes.editBtn}
                />
            </TableCell>
        </TableRow>
    )
}

export default VideoItem
