import firebase from 'firebase'
import moment from 'moment'

export enum DateDiffType {
    day = 1,
    hour = 2,
    minute = 3,
    second = 4,
}

class DateUtils {
    static differenceInDays = (compare: Date, today: Date = new Date()) => {
        const second = 1000
        const minute = second * 60
        const hour = minute * 60
        const day = hour * 24

        const date1Ms = compare.getTime()
        const date2Ms = today.getTime()

        // Calculate the difference in milliseconds
        const differenceMs = date2Ms - date1Ms

        // Convert back to days and return
        const dayDiff = Math.floor(differenceMs / day)
        if (dayDiff >= 3) return { option: 0, diff: dayDiff }
        if (dayDiff > 0 && dayDiff < 3) return { option: 1, diff: dayDiff }

        const hourDiff = Math.floor(differenceMs / hour)
        if (hourDiff > 0) return { option: 2, diff: hourDiff }

        const minuteDiff = Math.floor(differenceMs / minute)
        if (minuteDiff > 0) return { option: 3, diff: minuteDiff }

        const secondsDiff = Math.floor(differenceMs / second)
        return { option: 4, diff: secondsDiff }
    }

    static differenceInYears = (compare: Date, today: Date = new Date()) => {
        const second = 1000
        const minute = second * 60
        const hour = minute * 60
        const year = hour * 24 * 365

        const date1Ms = compare.getTime()
        const date2Ms = today.getTime()

        // Calculate the difference in milliseconds
        const differenceMs = date2Ms - date1Ms

        const yearsDiff = Math.floor(differenceMs / year)

        return { option: 0, diff: yearsDiff }
    }

    static timeConvert(timeInSeconds) {
        var pad = function (num, size) {
                return ('000' + num).slice(size * -1)
            },
            time = parseFloat(timeInSeconds).toFixed(3),
            // @ts-ignore
            hours = Math.floor(time / 60 / 60),
            // @ts-ignore
            minutes = Math.floor(time / 60) % 60,
            // @ts-ignore
            seconds = Math.floor(time - minutes * 60)
        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2)
    }

    static secondsToMinutes(timeInSeconds) {
        var pad = function (num, size) {
                return ('000' + num).slice(size * -1)
            },
            time = parseFloat(timeInSeconds).toFixed(3),
            // @ts-ignore
            minutes = Math.floor(time / 60) % 60,
            // @ts-ignore
            seconds = Math.floor(time - minutes * 60)
        return pad(minutes, 2) + ':' + pad(seconds, 2)
    }

    static isValidDateString(textDate: string) {
        const dateStr = textDate.trim()
        const day = parseInt(dateStr.slice(0, 2))
        const month = parseInt(dateStr.slice(3, 5))
        const year = dateStr.slice(6, 10)
        let dayCheck,
            monthCheck,
            yearCheck = false

        if (day > 0 && day < 32) {
            dayCheck = true
        }

        if (month > 0 && month < 13) {
            monthCheck = true
        }

        if (year.trim().length === 4) {
            yearCheck = true
        }

        return dayCheck && monthCheck && yearCheck
    }

    static isValidHourString(textHour: string) {
        const hourStr = textHour.trim()
        if (hourStr.length === 5) {
            const minutes = parseInt(hourStr.slice(0, 2))
            const seconds = parseInt(hourStr.slice(3, 5))
            if (minutes >= 0 && minutes <= 23) {
                return true
            }

            if (seconds >= 0 && seconds <= 60) {
                return true
            }
        }

        if (hourStr.length === 8) {
            const hour = parseInt(hourStr.slice(0, 2))
            const minutes = parseInt(hourStr.slice(3, 5))
            const seconds = parseInt(hourStr.slice(7, 9))

            if (hour >= 0 && hour <= 23) {
                return true
            }

            if (minutes >= 0 && minutes <= 60) {
                return true
            }

            if (seconds >= 0 && seconds <= 60) {
                return true
            }
        }

        return false
    }

    static stringToTimestamp = (dateString: string, shape: string) => {
        const todate: Date = moment(dateString, shape).toDate()
        return firebase.firestore.Timestamp.fromDate(todate)
    }
}

export default DateUtils
