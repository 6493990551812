import React, { useState, useEffect, useContext } from 'react'
import firebase from 'firebase'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Fab,
    TablePagination,
    LinearProgress,
    InputAdornment,
    TextField,
} from '@material-ui/core/'

import { Music } from '../../types/Musics'
import { BasePage } from '../../container'
import useStyles from './material.styles'
import styles from './styles.module.css'
import ApplicationContext from '../../context'
import MusicItem from './layouts/MusicItem'
import MusicForm from './layouts/Form'

const COLLECTION_NAME = 'background_music'
const ORDER_BY = 'createdAt'
const SORTED_BY = 'desc'

const MusicPage: React.FC = () => {
    const classes = useStyles()
    const [musics, setMusics] = useState<Music[]>([])
    const [searchText, setSearchText] = useState<string>('')
    const [rowsPerPage, setRowsPerPage] = useState<number>(25)
    const [page, setPage] = useState<number>(0)
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [isNew, setIsNew] = useState<boolean>(false)
    const [isInEdit, setIsInEdit] = useState<boolean>(false)
    const [musicToEdit, setMusicToEdit] = useState<Music>()

    const { showMessage, globalLoading } = useContext(ApplicationContext)

    useEffect(() => {
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .get()
            .then((snapshot) => {
                setTotal(snapshot.size)
            })
            .catch(() => showMessage('Falha ao pegar o total de items'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew, isInEdit])

    const updateMusics = (snapshot) => {
        const musicBuilder: any[] = []
        snapshot.forEach((doc) => {
            musicBuilder.push({
                docId: doc.id,
                ...doc.data(),
            })
        })
        setMusics(musicBuilder)
    }

    useEffect(() => {
        const existTextToSearch = !!searchText && searchText !== ''

        if (existTextToSearch) {
            setLoading(true)
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .get()
                .then((snapshot) => {
                    const musicBuilder: any[] = []
                    snapshot.forEach((doc) => {
                        if (
                            Object.values(doc.data())
                                .join(',')
                                .toUpperCase()
                                .match(searchText.toUpperCase())
                        )
                            musicBuilder.push({
                                docId: doc.id,
                                ...doc.data(),
                            })
                    })
                    setMusics(musicBuilder)
                })
                .catch((error) => {
                    console.log(error.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            if (musics.length === 0) {
                setLoading(true)
            }
            firebase
                .firestore()
                .collection(COLLECTION_NAME)
                .orderBy(ORDER_BY, SORTED_BY)
                .limit(rowsPerPage)
                .get()
                .then(updateMusics)
                .finally(() => {
                    setLoading(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, isNew, isInEdit])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setPage(newPage)

        let creator = firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .orderBy(ORDER_BY, SORTED_BY)

        if (page < newPage) {
            creator = creator
                .startAfter(musics[musics.length - 1][ORDER_BY])
                .limit(rowsPerPage)
        } else {
            creator = creator
                .endBefore(musics[0][ORDER_BY])
                .limitToLast(rowsPerPage)
        }

        creator

            .get()
            .then(updateMusics)
            .finally(() => setLoading(false))
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value))
        setPage(0)
        setLoading(true)
        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .orderBy(ORDER_BY, SORTED_BY)
            .limit(parseInt(event.target.value))
            .get()
            .then(updateMusics)
            .finally(() => {
                setLoading(false)
            })
    }

    const handleActiveOrDisable = (checked: boolean, docId: string) => {
        setLoading(true)
        const backupMusics = [...musics]
        const cloneMusics = [...musics]
        const index = cloneMusics.findIndex((med) => med.docId === docId)
        cloneMusics.splice(index, 1, {
            ...cloneMusics[index],
            active: checked,
        })
        setMusics(cloneMusics)

        firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .doc(docId)
            .set({ active: checked }, { merge: true })
            .then(() => {
                showMessage(`A música foi ${checked ? '' : 'in'}ativada`)
            })
            .catch((error) => {
                setMusics(backupMusics)
                showMessage(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleEdit = (tag: Music) => {
        setMusicToEdit(tag)
        setIsInEdit(true)
    }

    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    }

    const handleNewMeditation = () => {
        setIsNew(true)
    }

    const onCancelMeditation = () => {
        setIsNew(false)
        setIsInEdit(false)
        setMusicToEdit(undefined)
    }

    return (
        <BasePage>
            {(loading || globalLoading) && <LinearProgress />}
            <div className={styles.container}>
                <Fab
                    onClick={() => handleNewMeditation()}
                    classes={{ root: classes.actionButton }}
                    aria-label="add"
                >
                    <AddIcon classes={{ root: classes.addIcon }} />
                </Fab>
                {(isNew || isInEdit) && (
                    <MusicForm
                        music={musicToEdit}
                        onCancel={onCancelMeditation}
                        showList={() => onCancelMeditation()}
                    />
                )}
                {!isInEdit && (
                    <div className={styles.tableWrapper}>
                        <div className={styles.searchWrapper}>
                            <TextField
                                id="outlined-start-adornment"
                                value={searchText}
                                onChange={handleSearchText}
                                className={classes.searchField}
                                placeholder={'Localizar'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant={'outlined'}
                            />
                        </div>
                        <TableContainer component={Paper}>
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.tableTitle}
                                        >
                                            Título
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableActive}
                                        >
                                            Ativado
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {musics.map((row: Music, index: number) => (
                                        <MusicItem
                                            row={row}
                                            index={index}
                                            handleEdit={() => handleEdit(row)}
                                            handleActiveOrDisable={
                                                handleActiveOrDisable
                                            }
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={total}
                                className={classes.pagination}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage={'Itens por página:'}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </div>
                )}
            </div>
        </BasePage>
    )
}

export default MusicPage
