import React, { useState, useEffect, useCallback } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    LinearProgress,
    FormControl,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
} from '@material-ui/core/'
import SearchIcon from '@material-ui/icons/Search'
import { firestore } from 'firebase'
import { useHistory } from 'react-router-dom'
import useStyles from './material.styles'
import { User } from '../../types/User'
import UserItem from './layouts/UserItem'
import { BasePage } from '../../container'
import ApplicationContext from '../../context'
import styles from './styles.module.css'
import { routes } from '..'
import { cloneDeep } from 'lodash'
import useDebounce from '../../hooks/useDebounce'

enum ORDER_BY {
    DISPLAY_NAME = 'displayName',
    EMAIL = 'email',
}

const orderByDictionary = {
    [ORDER_BY.EMAIL]: 'E-mail',
    [ORDER_BY.DISPLAY_NAME]: 'Nome',
}

const INITIAL_LIMIT = 25
const INITIAL_PAGE = 0
const INITIAL_ORDER_BY = ORDER_BY.EMAIL
const COLLECTION_NAME = 'user_profile'

const Users: React.FC = () => {
    const history = useHistory()
    const classes = useStyles()

    const { showMessage } = React.useContext(ApplicationContext)
    const [users, setUsers] = useState<Array<User>>([])
    const [orderBy, setOrderBy] = useState<ORDER_BY>(INITIAL_ORDER_BY)
    const [limit, setLimit] = useState(INITIAL_LIMIT)
    const [page, setPage] = useState(INITIAL_PAGE)
    const [loading, setLoading] = useState(true)

    const [query, setQuery] = useState('')
    const debouncedQueryTerm = useDebounce(query, 500)
    const [queryUsers, setQueryUsers] = useState<Array<User>>([])

    const startLoading = () => setLoading(true)
    const finishLoading = () => setLoading(false)
    const handleCatch = useCallback(
        (error) => {
            console.log(error)
            showMessage(error.message)
        },
        [showMessage]
    )

    const documentToUser = (
        doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>
    ) =>
        ({
            id: doc.id,
            ...doc.data(),
        } as User)

    useEffect(() => {
        startLoading()
        firestore()
            .collection(COLLECTION_NAME)
            .get()
            .then((snapshot) => {
                const users = snapshot.docs.map(documentToUser)
                const data = users.sort()
                setUsers(data)
            })
            .catch(handleCatch)
            .finally(finishLoading)
    }, [handleCatch])

    useEffect(() => {
        startLoading()
        if (debouncedQueryTerm === '') {
            setPage(INITIAL_PAGE)
            setQueryUsers(users)
        } else {
            const filter = users.filter((it) => {
                const nameIncludes =
                    it.displayName &&
                    it.displayName.toLowerCase().includes(debouncedQueryTerm)
                const emailIncludes =
                    it.email &&
                    it.email.toLowerCase().includes(debouncedQueryTerm)
                return nameIncludes || emailIncludes
            })

            filter.sort((a, b) => {
                if (a[orderBy] < b[orderBy]) {
                    return -1
                }
                if (a[orderBy] > b[orderBy]) {
                    return 1
                }
                return 0
            })

            setQueryUsers(filter)
        }

        finishLoading()
    }, [debouncedQueryTerm, orderBy, users])

    const stableSort = (users: Array<User>, orderBy: ORDER_BY): Array<User> => {
        const clone = cloneDeep(users)

        clone.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return -1
            }
            if (a[orderBy] > b[orderBy]) {
                return 1
            }
            return 0
        })

        return clone
    }

    const handleChangeOrderBy = (event) => {
        const newOrderBy = event.target.value as ORDER_BY
        setOrderBy(newOrderBy)
        startLoading()
    }

    return (
        <BasePage>
            {loading && <LinearProgress />}
            <div className={styles.container}>
                <div className={styles.actionsContainer}>
                    <FormControl>
                        <TextField
                            id="outlined-start-adornment"
                            value={query}
                            onChange={(event) =>
                                setQuery(event.target.value ?? '')
                            }
                            className={classes.searchField}
                            placeholder={'Pesquisar por nome ou email...'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant={'outlined'}
                        />
                    </FormControl>
                    <FormControl>
                        <Select
                            value={orderBy}
                            onChange={handleChangeOrderBy}
                            displayEmpty
                            variant={'outlined'}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {Object.entries(ORDER_BY).map(([k, v]) => (
                                <MenuItem key={k} value={v}>
                                    {orderByDictionary[ORDER_BY[k]]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>Premium</TableCell>
                                <TableCell>Tag</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(queryUsers, orderBy)
                                .slice(page * limit, page * limit + limit)
                                .map((user) => (
                                    <UserItem
                                        key={user.id}
                                        onClick={() =>
                                            history.push(
                                                routes.userDetailPage.path,
                                                { user }
                                            )
                                        }
                                        {...user}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component={'div'}
                        count={queryUsers.length}
                        rowsPerPage={limit}
                        page={page}
                        labelRowsPerPage={'Itens por página:'}
                        onChangePage={(_, page) => {
                            setPage(page)
                        }}
                        onChangeRowsPerPage={(event) => {
                            setLimit(Number(event.target.value))
                        }}
                    />
                </TableContainer>
            </div>
        </BasePage>
    )
}

export default Users
