import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import ApplicationContext from '../../../context'
import { SCREEN_NAME_MAP } from '../../../views'
import styles from './styles.module.css'

const Header: React.FC = () => {
    const { doLogout } = useContext(ApplicationContext)
    const location = useLocation()
    return (
        <div className={styles.containerHeader}>
            <div className={styles.screenNameContainer}>
                <label className={styles.screenNameLabel}>
                    {SCREEN_NAME_MAP[location.pathname]}
                </label>
            </div>
            <div className={styles.rightContainer}>
                <label className={styles.adminLabel}>Admin</label>
                <label onClick={doLogout} className={styles.logoutBtn}>
                    Sair
                </label>
            </div>
        </div>
    )
}

export default Header
