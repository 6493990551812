import React from 'react'
import { TableRow, TableCell, withStyles, Checkbox } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'

import { Notification } from '../../../types/Notifications'
import colors from '../../../values/colors'
import useStyles from '../material.styles'
import styles from '../styles.module.css'

const GreenCheckbox = withStyles({
    root: {
        color: colors.blacks.one,
        '&$checked': {
            color: colors.greens.one,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

type Props = {
    row: Notification
    index: number
    handleEdit: (row: Notification) => void
    handleActiveOrDisable: (checked: boolean, docId: string) => void
}
const NotificationItem: React.FC<Props> = ({
    row,
    index,
    handleEdit,
    handleActiveOrDisable,
}) => {
    const classes = useStyles()

    const getStatus = () => {
        if (row.sended === undefined || row.sended === null) return '-'

        if (!!row.sended) return 'Sim'

        return 'Não'
    }

    return (
        <TableRow key={`${index}-${row.title}`}>
            <TableCell component="th" scope="row">
                {row.image && (
                    <img
                        src={row.image}
                        alt={row.title}
                        className={styles.tableImg}
                    />
                )}
            </TableCell>
            <TableCell className={classes.tableText}>{row.title}</TableCell>
            <TableCell className={classes.tableText}>
                {row.description}
            </TableCell>
            <TableCell className={classes.tableText}>{row.link}</TableCell>
            <TableCell className={classes.tableText}>
                {row.topics && row.topics.join(',')}
            </TableCell>
            <TableCell className={classes.tableText}>
                {moment(row.timestamp.seconds * 1000).format('hh:mm')}
            </TableCell>
            <TableCell className={classes.tableText}>
                <GreenCheckbox
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => handleActiveOrDisable(checked, row.docId!)}
                    checked={!!row.show}
                />
            </TableCell>
            <TableCell
                className={
                    !!row.sended ? classes.tableTextYes : classes.tableTextNo
                }
            >
                {getStatus()}
            </TableCell>
            <TableCell className={classes.tableText}>
                <EditIcon
                    onClick={() => handleEdit(row)}
                    className={classes.editBtn}
                />
            </TableCell>
        </TableRow>
    )
}

export default NotificationItem
