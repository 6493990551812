import { makeStyles } from '@material-ui/core/'
import globalStyles from '../../values/globalStyles'

const useStyles = makeStyles({
    searchField: {
        ...globalStyles.searchInput,
        marginRight: 8,
        width: 300,
    },
})

export default useStyles
